import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Error from "@mui/icons-material/Error";
import Replay from "@mui/icons-material/Replay";

import { CENTERED_FLEX_COLUMN_STYLES } from "../../constants";

interface DataRetrievalErrorProps {
  children: React.ReactNode;
  onRetry: () => void;
}

const DataRetrievalError: React.FC<DataRetrievalErrorProps> = ({
  children,
  onRetry,
}) => {
  return (
    <Box
      sx={{
        ...CENTERED_FLEX_COLUMN_STYLES,
        height: 200,
        justifyContent: "center",
      }}
    >
      <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginBottom={4}>
        <Error fontSize="large" color="warning" />
      </Box>
      <Typography
        color="text.primary"
        textAlign="center"
        variant="body1"
        marginBottom={2}
      >
        {children}
      </Typography>
      <Button startIcon={<Replay />} variant="text" onClick={onRetry}>
        Try Again
      </Button>
    </Box>
  );
};

export default DataRetrievalError;
