import { useState } from "react";

import { experimental_sx as sx } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";

import Help from "@mui/icons-material/Help";
import Warning from "@mui/icons-material/Warning";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { PrintQualityInfoText } from "../PrintQualityInfoText";

interface PixelsPerInchLabelProps extends TypographyProps {
  ppi: number;
}

const helpButtonStyles = {
  height: "0.95rem",
  width: "0.95rem",
  "& svg": sx({
    height: "0.95rem",
    width: "0.95rem",
    color: "text.secondary",
  }),
};

const PixelsPerInchLabel: React.FC<PixelsPerInchLabelProps> = ({
  ppi,
  ...rest
}) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const onInfoRequestClick = () => setShowInfoDialog(true);
  const onInfoDialogConfirm = () => setShowInfoDialog(false);

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" color="text.secondary" {...rest}>
        DPI: {ppi || ""}&nbsp;
      </Typography>
      <Typography variant="body2" display="flex" alignItems="center">
        {ppi >= 150 ? (
          <CheckCircle fontSize="inherit" color="success" />
        ) : (
          <Warning fontSize="inherit" color="warning" />
        )}
      </Typography>
      &nbsp;&nbsp;
      <Tooltip title="What's this?">
        <IconButton
          sx={helpButtonStyles}
          aria-label="What's this?"
          color="info"
          onClick={onInfoRequestClick}
        >
          <Help />
        </IconButton>
      </Tooltip>
      <Dialog open={showInfoDialog}>
        <DialogTitle>What is DPI?</DialogTitle>
        <DialogContent>
          <PrintQualityInfoText />
        </DialogContent>
        <DialogActions>
          <Button onClick={onInfoDialogConfirm}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PixelsPerInchLabel;
