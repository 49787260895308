import { useContext } from "react";
import { EtherPriceDataContext } from "../../context";
import { NetworkRequestState } from "../../types";
import { usePrintingPricesContext } from "../usePrintingPricesContext";

interface UseAppDataStatus {
  retrievingAppData: boolean;
  appDataRetrievalFailed: boolean;
}

const useAppDataStatus = (): UseAppDataStatus => {
  const { retrievingEtherPriceData, etherPriceDataRetrievalError } = useContext(
    EtherPriceDataContext
  );
  const { printingPricesRequestStatus } = usePrintingPricesContext();

  const printingPricesRequestFailed =
    printingPricesRequestStatus === NetworkRequestState.ERROR;

  const retrievingPrintingPricesData =
    printingPricesRequestStatus === NetworkRequestState.LOADING;

  const retrievingAppData =
    retrievingPrintingPricesData || retrievingEtherPriceData;

  const appDataRetrievalFailed =
    printingPricesRequestFailed || etherPriceDataRetrievalError;

  return {
    retrievingAppData,
    appDataRetrievalFailed,
  };
};

export default useAppDataStatus;
