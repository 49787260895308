import { createContext, useCallback, useState, useEffect } from "react";
import { EtherPriceData } from "../../types";
import { getEtherPriceData } from "../../network/ether";

interface EtherPriceDataContextValue {
  priceData: EtherPriceData | undefined;
  retrievingEtherPriceData: boolean;
  etherPriceDataRetrievalError: boolean;
  getEtherPriceData: () => void;
  clearEtherPriceRetrievalError: (tryAgain: boolean) => void;
}

const defaultContextValue: EtherPriceDataContextValue = {
  priceData: undefined,
  retrievingEtherPriceData: false,
  etherPriceDataRetrievalError: false,
  getEtherPriceData: () => {},
  clearEtherPriceRetrievalError: () => {},
};

const EtherPriceDataContext =
  createContext<EtherPriceDataContextValue>(defaultContextValue);

const EtherPriceDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [etherPriceData, setEtherPriceData] = useState<
    EtherPriceData | undefined
  >(undefined);
  const [retrievingEtherPriceData, setRetrievingEtherPriceData] =
    useState<boolean>(false);
  const [etherPriceDataRetrievalError, setEtherPriceDataRetrievalError] =
    useState<boolean>(false);

  const doRequest = useCallback(() => {
    setEtherPriceDataRetrievalError(false);
    setRetrievingEtherPriceData(true);
    getEtherPriceData()
      .then((etherPriceData) => {
        setEtherPriceData({
          price: Number(etherPriceData.amount)
        })
        setRetrievingEtherPriceData(false);
      })
      .catch(() => {
        setRetrievingEtherPriceData(false);
        setEtherPriceDataRetrievalError(true);
      });
  }, []);

  const clearEtherPriceRetrievalError = useCallback(
    (tryAgain: boolean = false) => {
      setEtherPriceDataRetrievalError(false);

      if (tryAgain) {
        doRequest();
      }
    },
    [doRequest]
  );

  useEffect(() => {
    const interval = setInterval(doRequest, 300000);

    return () => {
      clearInterval(interval);
    };
  }, [doRequest]);

  const contextValue: EtherPriceDataContextValue = {
    priceData: etherPriceData,
    retrievingEtherPriceData,
    etherPriceDataRetrievalError,
    getEtherPriceData: doRequest,
    clearEtherPriceRetrievalError,
  };

  return (
    <EtherPriceDataContext.Provider value={contextValue}>
      {children}
    </EtherPriceDataContext.Provider>
  );
};

export default EtherPriceDataContext;
export { EtherPriceDataProvider };
