import { AUTH_ERRORS, AUTH_TOKEN_KEY } from "../constants";

const checkStatus = (response: Response) => {
  const { status } = response;

  if (status === 401) {
    throw new Error(AUTH_ERRORS.INVALID_TOKEN);
  }

  return response;
};

export const authFetch = (
  input: URL | string,
  init?: RequestInit | undefined
): Promise<Response> => {
  const jwt = localStorage.getItem(AUTH_TOKEN_KEY);

  return fetch(input.toString(), {
    ...init,
    headers: {
      ...init?.headers,
      authorization: `Bearer: ${jwt}`,
    },
  }).then(checkStatus);
};
