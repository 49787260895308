import { useEthers } from "@usedapp/core";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useOwnedNFTs } from "../../hooks/useOwnedNFTs";
import { usePrintPreviews } from "../../hooks/usePrintPreviews";
import {
  NetworkRequestState,
  NFTData,
  PosterVariantKeys,
  PrintPreview,
} from "../../types";
import { getImageSrc } from "../../utils";
import { PRINT_ORDER_ROUTES, ROUTES } from "../../constants";
import { useWindowScroll } from "@uidotdev/usehooks";

export interface ProductSelection {
  onPrintPreviewSelect: (printPreviewVariant: string) => void;
  onNFTSelect: (tokenId: string) => void;
  selectedNFT: NFTData | null;
  selectedPrintPreview: PrintPreview | null;
  printPreviews: PrintPreview[];
  ownedNFTsData: NFTData[];
  retrievingOwnedNFTs: boolean;
  printPreviewsRequestStatus: NetworkRequestState;
  clearPrintPreviewsRequestStatus: () => void;
}

const ProductSelectionContext = createContext<ProductSelection | undefined>(
  undefined
);

const ProductSelectionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const [{ y: scrollYPosition }] = useWindowScroll();
  const { account } = useEthers();
  const { token } = useAuthContext();
  const [selectedNFT, setSelectedNFT] = useState<NFTData | null>(null);
  const [selectedPrintPreview, setSelectedPrintPreview] =
    useState<PrintPreview | null>(null);

  const {
    printPreviews,
    printPreviewsRequestStatus,
    clearPrintPreviewsRequestStatus,
    getPrintPreviews,
    setPrintPreviews,
  } = usePrintPreviews();
  const {
    setOwner,
    retrieveAdditionalNFTs,
    ownedNFTsData,
    retrievingOwnedNFTs,
  } = useOwnedNFTs();

  useEffect(() => {
    const nftSelectRoute = `${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`;
    const onSelectRoute = location.pathname.includes(nftSelectRoute);

    if (!onSelectRoute) {
      return;
    }

    const scrollHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;

    const ninetyPercentScrollThreshold = Math.floor(scrollHeight * 0.9);
    const retrieveAdditionalScrollThresholdMet =
      windowHeight + (scrollYPosition || 0) > ninetyPercentScrollThreshold;

    if (retrieveAdditionalScrollThresholdMet && !retrievingOwnedNFTs) {
      retrieveAdditionalNFTs();
    }
  }, [
    ownedNFTsData.length,
    scrollYPosition,
    location.pathname,
    retrievingOwnedNFTs,
    retrieveAdditionalNFTs,
  ]);

  const onNFTSelect = useCallback(
    (tokenValue: string) => {
      // string value from generateNFTValueString(ownedNFTData)
      const nftName = tokenValue.split(".")[0] || "";
      const nftTokenId = tokenValue.split(".")[1];
      const selection = ownedNFTsData.find(
        (nft) => nft.id.tokenId === nftTokenId && nftName === nft.metadata.name
      );

      if (!selection) {
        return;
      }

      setSelectedNFT(selection);
      setPrintPreviews([]);
    },
    [ownedNFTsData, setPrintPreviews]
  );

  const onPrintPreviewSelect = useCallback(
    (printPreviewVariant: string) => {
      const selection = printPreviews.find(
        (printPreview) =>
          printPreview.variant === (printPreviewVariant as PosterVariantKeys)
      );

      if (!selection) {
        return;
      }

      setSelectedPrintPreview(selection);
    },
    [printPreviews]
  );

  useEffect(() => {
    setSelectedPrintPreview(null);
  }, [selectedNFT]);

  useEffect(() => {
    if (!selectedNFT?.metadata?.image) {
      return;
    }

    setSelectedPrintPreview(null);

    getPrintPreviews(getImageSrc(selectedNFT?.media[0].gateway));
  }, [selectedNFT, getPrintPreviews]);

  useEffect(() => {
    if (printPreviews.length > 0) {
      setSelectedPrintPreview(printPreviews[0]);
    }
  }, [printPreviews]);

  useEffect(() => {
    if (!account || !token) {
      setSelectedNFT(null);
      setSelectedPrintPreview(null);
      setPrintPreviews([]);
      return;
    }

    if (account) {
      setOwner(account);
    }
  }, [account, token, setOwner, setPrintPreviews]);

  const productSelectionInterface: ProductSelection = {
    onPrintPreviewSelect,
    clearPrintPreviewsRequestStatus,
    onNFTSelect,
    selectedNFT,
    selectedPrintPreview,
    printPreviews,
    printPreviewsRequestStatus,
    ownedNFTsData,
    retrievingOwnedNFTs,
  };

  return (
    <ProductSelectionContext.Provider value={productSelectionInterface}>
      {children}
    </ProductSelectionContext.Provider>
  );
};

export default ProductSelectionContext;
export { ProductSelectionProvider };
