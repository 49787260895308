import { AUTH_ERRORS } from "../constants";
import {
  AuthUserResponse,
  CreateUserResponse,
  GetUserNonceResponse,
  User,
} from "../types";

const API_BASE_URL = "https://users-api.walletprints.com";

export const getUserExists = (walletAddress: string) => {
  return fetch(`${API_BASE_URL}/${walletAddress}/exists`).then((response) => {
    const { status } = response;

    switch (status) {
      case 200:
        return true;
      case 404:
        return false;
      default:
        throw new Error(AUTH_ERRORS.SERVER_ERROR);
    }
  });
};

export const getUserNonce = (walletAddress: string): Promise<string> => {
  return fetch(`${API_BASE_URL}/${walletAddress}/nonce`)
    .then((response) => {
      const { status } = response;

      switch (status) {
        case 404:
          console.error("Retrieving nonce failed: user not found");
          throw new Error(AUTH_ERRORS.USER_NOT_FOUND);
        case 500:
          console.error("Retrieving nonce failed: server error");
          throw new Error(AUTH_ERRORS.SERVER_ERROR);
        default: {
          const responseData =
            response.json() as unknown as GetUserNonceResponse;

          return responseData;
        }
      }
    })
    .then((responseData) => responseData.data);
};

export const createUser = (
  walletAddress: string,
  recaptchaToken: string
): Promise<User> => {
  return fetch(`${API_BASE_URL}`, {
    method: "POST",
    body: JSON.stringify({
      walletAddress,
      recaptchaToken,
    }),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      const { status } = response;

      switch (status) {
        case 200: {
          const responseData = response.json() as unknown as CreateUserResponse;

          return responseData;
        }
        default:
          console.error("Could not create user");
          throw new Error(AUTH_ERRORS.SERVER_ERROR);
      }
    })
    .then((responseData) => responseData.data);
};

export const authUser = (
  walletAddress: string,
  signature: string
): Promise<string> => {
  return fetch(`${API_BASE_URL}/${walletAddress}/signature`, {
    method: "POST",
    body: JSON.stringify({
      signature,
    }),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      const { status } = response;

      switch (status) {
        case 400:
          console.error("Missing auth data");
          throw new Error(AUTH_ERRORS.MISSING_DATA);
        case 401:
          console.error("Invalid auth signature");
          throw new Error(AUTH_ERRORS.INVALID_SIGNATURE);
        case 500:
          console.error("Unknown error while authenticating user");
          throw new Error(AUTH_ERRORS.SERVER_ERROR);
        default:
          const responseData = response.json() as unknown as AuthUserResponse;

          return responseData;
      }
    })
    .then((responseData) => responseData.data);
};
