import { useContext } from "react";
import { OrdersContext } from "../../context";

const useOrdersContext = () => {
  const contextValue = useContext(OrdersContext);

  if (!contextValue) {
    throw new Error("Component must be wrapped in OrdersContextProvider");
  }

  return contextValue;
};

export default useOrdersContext;
