import { useCallback } from "react";
import { useEthers } from "@usedapp/core";
import { DateTime } from "luxon";

import { Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SectionHeader } from "../../components/SectionHeader";
import { DataRetrievalError } from "../../components/DataRetrievalError";
import { useOrdersContext } from "../../hooks/useOrdersContext";
import {
  CENTERED_FLEX_COLUMN_STYLES,
  ORDER_STATUS_STRINGS,
  POSTER_VARIANT_LABELS,
  PRINT_ORDER_ROUTES,
  PRODUCT_LABELS,
  ROUTES,
} from "../../constants";
import {
  NetworkRequestState,
  NFTBaseData,
  OrderDetails,
  PosterVariantKeys,
} from "../../types";
import { useProductSelection } from "../../hooks/useProductSelection";

interface OrderCardProps {
  order: OrderDetails;
}

interface OrderNameProps {
  variant: PosterVariantKeys;
  nftData: NFTBaseData;
}

export const OrderName: React.FC<OrderNameProps> = ({ variant, nftData }) => {
  return (
    <Box marginRight={4}>
      <Typography variant="h5">
        {PRODUCT_LABELS.FRAMED_POSTER} {POSTER_VARIANT_LABELS[variant]}
      </Typography>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        {nftData.name}
      </Typography>
    </Box>
  );
};

interface OrderDatesProps {
  created: number;
  updated: number;
}

export const OrderDates: React.FC<OrderDatesProps> = ({ created, updated }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      justifySelf="end"
      alignItems="start"
    >
      <Typography variant="caption" color="text.secondary">
        Created {DateTime.fromSeconds(created).toLocaleString()}
        {" @ "}
        {DateTime.fromSeconds(created).toJSDate().toLocaleTimeString()}
      </Typography>

      <Typography variant="caption" color="text.secondary">
        Updated {DateTime.fromSeconds(updated).toLocaleString()}
        {" @ "}
        {DateTime.fromSeconds(updated).toJSDate().toLocaleTimeString()}
      </Typography>
    </Box>
  );
};

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
  const theme = useTheme();
  const mobileLayout = useMediaQuery(theme.breakpoints.down("sm"));

  const cardStyles = mobileLayout ? undefined : { display: "flex" };
  const cardMediaStyles = mobileLayout ? { margin: "auto" } : undefined;
  const cardActionsStyles = mobileLayout
    ? { justifyContent: "flex-end" }
    : undefined;

  return (
    <Card sx={{ ...cardStyles, width: "100%" }}>
      <CardMedia
        component="img"
        sx={{ width: 151, ...cardMediaStyles }}
        image={order.printPreviewUrl}
      />
      <CardContent
        sx={{
          display: "flex",
          alignItems: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box width="100%" display="flex">
          <Box
            display="flex"
            justifyContent="space-between"
            flexGrow="1"
            flexWrap="wrap"
          >
            <OrderName variant={order.variant} nftData={order.nftData} />
            <Box justifyContent="end">
              <Typography variant="body1" color="text.secondary">
                {ORDER_STATUS_STRINGS[order.status]}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box flexGrow="1" alignSelf="flex-end" marginRight={4}>
            <Typography variant="h6" color="text.secondary">
              {order.costs.total} {order.costs.currency}
            </Typography>
          </Box>
          <OrderDates created={order.created} updated={order.updated} />
        </Box>
      </CardContent>
      <CardActions sx={cardActionsStyles}>
        <Link
          to={`/orders/${order.id}`}
          style={{ height: "100%", textDecoration: "none" }}
        >
          <Button endIcon={<KeyboardArrowRight />} sx={{ height: "100%" }}>
            View Details
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

const OrdersList = () => {
  const navigate = useNavigate();
  const { account } = useEthers();
  const { orders, getPrintOrders, ordersRetrievalStatus } = useOrdersContext();
  const { ownedNFTsData } = useProductSelection();

  const onOrdersRetrievalRetry = useCallback(() => {
    if (!account) {
      return;
    }

    getPrintOrders(account);
  }, [account, getPrintOrders]);

  const onOrderFirstPrintCTAClick = useCallback(() => {
    navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`);
  }, [navigate]);

  const ownedNFTsWithImages = ownedNFTsData.filter(
    (ownedNFT) => ownedNFT.metadata.image
  );

  return (
    <>
      {ordersRetrievalStatus === NetworkRequestState.LOADING && (
        <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginTop={4}>
          <SectionHeader>Retrieving orders...</SectionHeader>
        </Box>
      )}
      {ordersRetrievalStatus === NetworkRequestState.SUCCESS &&
        orders.length === 0 && (
          <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginTop={4}>
            <SectionHeader>No print orders found</SectionHeader>
            {ownedNFTsWithImages.length > 0 && (
              <Button variant="contained" onClick={onOrderFirstPrintCTAClick}>
                Order your first print
              </Button>
            )}
          </Box>
        )}
      {ordersRetrievalStatus === NetworkRequestState.SUCCESS &&
        orders.length > 0 && (
          <List>
            {orders.map((order) => (
              <ListItem
                key={order.id}
                alignItems="flex-start"
                sx={{ paddingX: 0 }}
              >
                <OrderCard key={order.id} order={order} />
              </ListItem>
            ))}
          </List>
        )}
      {ordersRetrievalStatus === NetworkRequestState.ERROR && (
        <DataRetrievalError onRetry={onOrdersRetrievalRetry}>
          Could not retrieve orders
        </DataRetrievalError>
      )}
    </>
  );
};

export default OrdersList;
