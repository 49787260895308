import React, { useCallback, useContext, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";

import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { useForm } from "react-hook-form";

import ControlledAutocomplete from "./ControlledAutocomplete";
import { ShippingDataContext } from "../../context";
import { DEFAULT_SHIPPING_FORM_VALUES } from "../../constants";
import { Country, ShippingFormValues } from "../../types";
import { useOrderEstimateContext } from "../../hooks/useOrderEstimateContext";
import { DataRetrievalError } from "../DataRetrievalError";

interface ShippingInfoFormProps {
  onSubmit: (shippingData: ShippingFormValues) => void;
  children: React.ReactNode;
}

const ShippingInfoForm: React.VFC<ShippingInfoFormProps> = ({
  onSubmit,
  children,
}) => {
  const { retrievingOrderEstimate } = useOrderEstimateContext();
  const {
    clearError: clearAcceptedCountriesRetrievalError,
    retrieveAcceptedCountries,
    retrievingAcceptedCountries,
    acceptedCountriesRetrievalError,
    acceptedCountries,
    setShippingData,
    shippingData,
  } = useContext(ShippingDataContext);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState,
    getValues,
  } = useForm<ShippingFormValues>({
    defaultValues: shippingData || DEFAULT_SHIPPING_FORM_VALUES,
  });
  const { errors } = formState;

  const countryCode = watch("countryCode");
  const getSelectedCountry = useCallback(
    (countryCode: string) =>
      acceptedCountries &&
      (acceptedCountries.find(
        (country) => country.code === countryCode
      ) as Country),
    [acceptedCountries]
  );

  const selectedCountry = useMemo(
    () => getSelectedCountry(countryCode),
    [countryCode, getSelectedCountry]
  );

  const shouldCollectState =
    selectedCountry &&
    selectedCountry.states &&
    selectedCountry.states.length > 0;

  const defaultCountryValue = acceptedCountries.find(
    (country) => country.code === selectedCountry?.code || country.code === "US"
  );

  const filledState =
    shippingData && selectedCountry && selectedCountry?.states
      ? selectedCountry?.states.find(
          (state) => state.code === shippingData?.stateCode
        )
      : null;

  const defaultStateValue =
    filledState && filledState !== null
      ? filledState
      : selectedCountry?.states
      ? selectedCountry.states[selectedCountry.states.length - 1]
      : null;

  const fieldRequired = (value: string | undefined): string | undefined =>
    !value || value.trim().length === 0 ? "This field is required" : undefined;

  const onFormSubmit = useCallback(() => {
    setShippingData(getValues());
    onSubmit(getValues());
  }, [getValues, setShippingData, onSubmit]);

  useEffect(() => {
    setValue("countryCode", "US");
  }, [acceptedCountries, setValue]);

  useEffect(() => {
    if (!selectedCountry) {
      return;
    }

    if (selectedCountry.states && selectedCountry.states.length > 0) {
      setValue(
        "stateCode",
        selectedCountry.states[selectedCountry.states.length - 1].code
      );

      return;
    }

    setValue("stateCode", "");
  }, [countryCode, selectedCountry, setValue]);

  useEffect(() => {
    if (
      !acceptedCountries.length &&
      !retrievingAcceptedCountries &&
      !acceptedCountriesRetrievalError
    ) {
      retrieveAcceptedCountries();
      return;
    }
  }, [
    acceptedCountries,
    acceptedCountriesRetrievalError,
    retrievingAcceptedCountries,
    retrieveAcceptedCountries,
  ]);

  useEffect(() => {
    if (shippingData) {
      setValue("countryCode", shippingData.countryCode);
      setValue("stateCode", shippingData.stateCode);
    }
  }, [shippingData, setValue]);

  useEffect(() => {
    return () => {
      clearAcceptedCountriesRetrievalError(false);
    };
  }, [clearAcceptedCountriesRetrievalError]);

  const nameError = errors?.name?.message;
  const address1Error = errors?.address1?.message;
  const cityError = errors?.city?.message;
  const zipError = errors?.zip?.message;
  const countryError = errors?.countryCode?.message;
  const stateError = errors?.stateCode?.message;

  const formDisabled =
    retrievingAcceptedCountries ||
    acceptedCountriesRetrievalError ||
    retrievingOrderEstimate;

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {!acceptedCountriesRetrievalError && (
          <>
            <TextField
              size="small"
              variant="outlined"
              id="name"
              label="Recipient name"
              error={!!nameError}
              helperText={nameError}
              disabled={formDisabled}
              {...register("name", {
                validate: {
                  notEmpty: fieldRequired,
                },
              })}
            />
            <TextField
              size="small"
              variant="outlined"
              id="address1"
              label="Address 1"
              error={!!address1Error}
              helperText={address1Error}
              disabled={formDisabled}
              {...register("address1", {
                validate: {
                  notEmpty: fieldRequired,
                },
              })}
            />
            <TextField
              size="small"
              variant="outlined"
              id="address2"
              label="Address 2"
              disabled={formDisabled}
              {...register("address2")}
            />
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  id="city"
                  label="City"
                  error={!!cityError}
                  helperText={cityError}
                  disabled={formDisabled}
                  {...register("city", {
                    validate: {
                      notEmpty: fieldRequired,
                    },
                  })}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  id="zip"
                  label="Postal Code"
                  error={!!zipError}
                  helperText={zipError}
                  disabled={formDisabled}
                  {...register("zip", {
                    validate: {
                      notEmpty: fieldRequired,
                    },
                  })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {!retrievingAcceptedCountries &&
                !acceptedCountriesRetrievalError && (
                  <>
                    <Grid item sm={6} xs={12}>
                      {defaultCountryValue && (
                        <ControlledAutocomplete
                          control={control}
                          name="countryCode"
                          label="Country"
                          defaultValue={defaultCountryValue}
                          options={acceptedCountries}
                          validate={{
                            notEmpty: fieldRequired,
                          }}
                          error={countryError}
                          disabled={formDisabled}
                        />
                      )}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {shouldCollectState &&
                        selectedCountry.states &&
                        defaultStateValue && (
                          <ControlledAutocomplete
                            control={control}
                            name="stateCode"
                            label="State"
                            defaultValue={defaultStateValue}
                            options={selectedCountry.states}
                            validate={{
                              notEmpty: fieldRequired,
                            }}
                            error={stateError}
                            disabled={formDisabled}
                          />
                        )}
                    </Grid>
                  </>
                )}
              {retrievingAcceptedCountries && (
                <>
                  <Grid item sm={6} xs={12}>
                    <Skeleton variant="rectangular" height={40} />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Skeleton variant="rectangular" height={40} />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}

        {!retrievingAcceptedCountries && acceptedCountriesRetrievalError && (
          <Grid item xs={12}>
            <DataRetrievalError
              onRetry={() => clearAcceptedCountriesRetrievalError(false)}
            >
              Could not retrieve list of countries
            </DataRetrievalError>
          </Grid>
        )}

        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {children}
          <Button
            variant="outlined"
            type="submit"
            disabled={formDisabled}
            endIcon={
              retrievingOrderEstimate ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <KeyboardArrowRight />
              )
            }
          >
            Review Order
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ShippingInfoForm;
