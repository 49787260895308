import Box from "@mui/material/Box";

const OrderStepContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      maxWidth="640px"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="auto"
    >
      {children}
    </Box>
  );
};

export default OrderStepContent;
