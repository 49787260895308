import { getThumbnailStyles } from "../../utils";

interface ImageThumbnailProps {
  imageUri: string;
}

function ImageThumbnail({ imageUri }: ImageThumbnailProps) {
  return <div style={getThumbnailStyles(imageUri)} />;
}

export default ImageThumbnail;
