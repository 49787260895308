import { useContext } from "react";
import { AuthContext } from "../../context";

const useAuthContext = () => {
  const contextValue = useContext(AuthContext);

  if (!contextValue) {
    throw new Error("Component must be wrapped in AuthContextProvider");
  }

  return contextValue;
};

export default useAuthContext;
