import { SxProps, Theme } from "@mui/material";
import {
  PosterVariantKeys,
  PrintfulOrderStatus,
  ShippingFormValues,
} from "./types";

export const AUTH_ERRORS = {
  USER_NOT_FOUND: "USER_NOT_FOUND",
  SERVER_ERROR: "SERVER_ERROR",
  MISSING_DATA: "MISSING_DATA",
  INVALID_SIGNATURE: "INVALID_SIGNATURE",
  INVALID_TOKEN: "INVALID_TOKEN",
};

const {
  FRAMED_POSTER_12_BY_16,
  FRAMED_POSTER_14_BY_14,
  FRAMED_POSTER_16_BY_20,
} = PosterVariantKeys;

export const PRODUCT_LABELS = {
  FRAMED_POSTER: "Framed Poster",
};

export const POSTER_VARIANT_LABELS = {
  [FRAMED_POSTER_12_BY_16]: "12″ × 16″",
  [FRAMED_POSTER_14_BY_14]: "14″ × 14″",
  [FRAMED_POSTER_16_BY_20]: "16″ × 20″",
};

export const CENTERED_FLEX_COLUMN_STYLES: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const ROUTES = {
  ORDERS: "orders",
  PRINT: "print",
  LANDING: "landing",
};

export const PRINT_ORDER_ROUTES = {
  PRINT_OPTION_SELECT: "select",
  NFT_SELECT: "nft-select",
  SIZE_SELECT: "size-select",
  SHIPPING_INFO_COLLECTION: "shipping",
  ORDER_REVIEW: "review",
};

export const DEFAULT_SHIPPING_FORM_VALUES: ShippingFormValues = {
  name: "",
  address1: "",
  address2: "",
  city: "",
  stateCode: "",
  countryCode: "",
  zip: "",
};

export const ORDER_ESTIMATE_ERRORS = {
  INVALID_ADDRESS: "INVALID_ADDRESS",
};

export const GENERAL_ERRORS = {
  PRINTING_PRICES_RETRIEVAL_ERROR: "PRINTING_PRICES_RETRIEVAL_ERROR",
  NETWORK_ERROR: "NETWORK_ERROR",
};

export const ORDER_SUBMISSION_ERRORS = {
  TRANSACTION_QUERY_ERROR: "TRANSACTION_QUERY_ERROR",
  TRANSACTION_VERIFICATION_ERROR: "TRANSACTION_VERIFICATION_ERROR",
  ORDER_IMAGE_SAVE_ERROR: "ORDER_IMAGE_SAVE_ERROR",
  FULFILLER_ORDER_SUBMIT_ERROR: "FULFILLER_ORDER_SUBMIT_ERROR",
  ORDER_RECORD_SAVE_ERROR: "ORDER_RECORD_SAVE_ERROR",
  ORDER_ID_ASSOCATION_ERROR: "ORDER_ID_ASSOCATION_ERROR",
  NFT_PAYLOAD_VERIFICATION_ERROR: "NFT_PAYLOAD_VERIFICATION_ERROR",
  RECIPIENT_PAYLOAD_VERIFICATION_ERROR: "RECIPIENT_PAYLOAD_VERIFICATION_ERROR",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
};

const MALFORMED_ORDER_REQUEST_ERROR =
  "The order submission request was malformed. The order could not be submitted.";

export const ORDER_SUBMISSION_ERROR_STRINGS = {
  [ORDER_SUBMISSION_ERRORS.TRANSACTION_QUERY_ERROR]:
    "An error occurred while querying the blockchain transaction for verification. Please contact us for support.",
  [ORDER_SUBMISSION_ERRORS.TRANSACTION_VERIFICATION_ERROR]:
    "The blockchain transaction parameters did not match what was quoted. The order was not submitted.",
  [ORDER_SUBMISSION_ERRORS.ORDER_ID_ASSOCATION_ERROR]:
    "The order was submitted successfully but could not be associated with your account. The order is expected to be fulfilled, but no tracking information can be provided at this time.",
  [ORDER_SUBMISSION_ERRORS.ORDER_IMAGE_SAVE_ERROR]:
    "The order was submitted successfully, but the print preview could not be saved. The order is expected to be fulfilled.",
  [ORDER_SUBMISSION_ERRORS.ORDER_RECORD_SAVE_ERROR]:
    "The order was submitted successfully, but a record for the order could not be saved. The order is expected to be fulfilled, but no tracking information can be provided at this time.",
  [ORDER_SUBMISSION_ERRORS.FULFILLER_ORDER_SUBMIT_ERROR]:
    "There was a problem submitting the order for fulfillment. Please contact us for support.",
  [ORDER_SUBMISSION_ERRORS.NFT_PAYLOAD_VERIFICATION_ERROR]:
    MALFORMED_ORDER_REQUEST_ERROR,
  [ORDER_SUBMISSION_ERRORS.RECIPIENT_PAYLOAD_VERIFICATION_ERROR]:
    MALFORMED_ORDER_REQUEST_ERROR,
  [ORDER_SUBMISSION_ERRORS.UNKNOWN_ERROR]:
    "An unknown error occurred while submitting the print order for fulfillment. Please contact us for support.",
  [GENERAL_ERRORS.NETWORK_ERROR]:
    "A network error occurred while submitting the print order for fulfillment. Please contact us for support.",
};

export const ORDER_REQUEST_ERRORS = {
  ORDER_DETAILS_RETRIEVAL_ERROR: "ORDER_DETAILS_RETRIEVAL_ERROR",
  GET_ORDERS_LIST_ERROR: "GET_ORDERS_LIST_ERROR",
};

export const INSUFFICIENT_FUNDS_ERROR_STRING = "insufficient funds";

export const ORDER_STATUS_STRINGS = {
  [PrintfulOrderStatus.DRAFT]: "Draft",
  [PrintfulOrderStatus.PENDING]: "Pending",
  [PrintfulOrderStatus.FAILED]: "Failed",
  [PrintfulOrderStatus.CANCELED]: "Canceled",
  [PrintfulOrderStatus.IN_PROCESS]: "In progress",
  [PrintfulOrderStatus.ON_HOLD]: "On hold",
  [PrintfulOrderStatus.PARTIAL]: "Partial",
  [PrintfulOrderStatus.FULFILLED]: "Fulfilled",
  [PrintfulOrderStatus.ARCHIVED]: "Archived",
};

export const TRANSACTION_REQUEST_REJECTED_MESSAGE = "user rejected transaction";

export const AUTH_TOKEN_KEY = "__WALLETPRINTS_AUTH";

export const EXPECTED_ETH_DESTINATION_WALLET_ADDRESS =
  "0x0F0f2E5Ba39Be622eCB7b11D381165A7B4b1f5d9";

export const RECAPTCHA_V3_SITE_KEY = "6Le0HmQpAAAAAINFhE-WG62pojBYgsnHYQAwOExp";
export const DISCORD_INVITE_LINK = "https://discord.gg/BduktV2A";
export const DESKTOP_LOGO_URL =
  "https://wp--static-assets.s3.us-west-1.amazonaws.com/wallet-prints-logo--inverted-crop-precise.png";
export const MOBILE_LOGO_URL =
  "https://wp--static-assets.s3.us-west-1.amazonaws.com/wallet-prints-logo--inverted-crop-precise--mobile.png";
