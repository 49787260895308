import { createContext, useCallback, useState } from "react";

import { getOrderEstimate } from "../../network/prints";
import {
  OrderCosts,
  OrderRecipient,
  PosterVariantKeys,
  SupportedCurrencies,
} from "../../types";
import { useAuthErrorHandling } from "../../hooks/useAuthErrorHandling";
import { isInvalidTokenError } from "../../utils";

export interface ContextValue {
  retrieveOrderEstimate: (
    recipient: OrderRecipient,
    variant: PosterVariantKeys,
    imageUrl: string
  ) => void;
  clearOrderEstimateRetrievalError: () => void;
  clearOrderEstimateData: () => void;
  orderEstimate: OrderCosts | null;
  orderEstimateRetrievalError: string;
  retrievingOrderEstimate: boolean;
  checkoutId: string;
}

const OrderEstimateContext = createContext<ContextValue | undefined>(undefined);

const OrderEstimateContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { onInvalidTokenError } = useAuthErrorHandling();
  const [orderEstimate, setOrderEstimate] = useState<OrderCosts | null>(null);
  const [checkoutId, setCheckoutId] = useState("");
  const [retrievingOrderEstimate, setRetrievingOrderEstimate] =
    useState<boolean>(false);
  const [orderEstimateRetrievalError, setOrderEstimateRetrievalError] =
    useState<string>("");

  const clearOrderEstimateRetrievalError = useCallback(() => {
    setOrderEstimateRetrievalError("");
  }, []);

  const clearOrderEstimateData = useCallback(() => {
    setOrderEstimate(null);
  }, []);

  const retrieveOrderEstimate = useCallback(
    (
      recipient: OrderRecipient,
      variant: PosterVariantKeys,
      imageUrl: string
    ) => {
      clearOrderEstimateRetrievalError();
      setRetrievingOrderEstimate(true);
      getOrderEstimate(recipient, variant, imageUrl, SupportedCurrencies.ETHER)
        .then((orderEstimateResponseData) => {
          setOrderEstimate(orderEstimateResponseData.data.costs);
          setCheckoutId(orderEstimateResponseData.data.checkoutId);
          setRetrievingOrderEstimate(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          setRetrievingOrderEstimate(false);

          if (isInvalidTokenError(error)) {
            return onInvalidTokenError();
          }

          setOrderEstimateRetrievalError(error?.message);
        });
    },
    [onInvalidTokenError, clearOrderEstimateRetrievalError]
  );

  const contextValue = {
    retrieveOrderEstimate,
    clearOrderEstimateRetrievalError,
    clearOrderEstimateData,
    orderEstimate,
    orderEstimateRetrievalError,
    retrievingOrderEstimate,
    checkoutId,
  };

  return (
    <OrderEstimateContext.Provider value={contextValue}>
      {children}
    </OrderEstimateContext.Provider>
  );
};

export default OrderEstimateContext;
export { OrderEstimateContextProvider };
