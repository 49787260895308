import Box from "@mui/material/Box";

const LineItem: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        "&:not(:last-child)": {
          marginBottom: 0.5,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default LineItem;
