import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEthers, useSendTransaction } from "@usedapp/core";
import { utils } from "ethers";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Skeleton from "@mui/material/Skeleton";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { OrderSummaryCard } from "../../components/OrderSummaryCard";
import { useProductSelection } from "../../hooks/useProductSelection";
import { useOrdersContext } from "../../hooks/useOrdersContext";
import { useOrderEstimateContext } from "../../hooks/useOrderEstimateContext";
import { SectionHeader } from "../../components/SectionHeader";
import { OrderStepContent } from "../../components/OrderStepContent";
import CostBreakdown from "./CostBreakdown";
import Shipping from "./Shipping";
import { ShippingDataContext, EtherPriceDataContext } from "../../context";
import {
  CENTERED_FLEX_COLUMN_STYLES,
  EXPECTED_ETH_DESTINATION_WALLET_ADDRESS,
  INSUFFICIENT_FUNDS_ERROR_STRING,
  ORDER_SUBMISSION_ERROR_STRINGS,
  PRINT_ORDER_ROUTES,
  ROUTES,
  TRANSACTION_REQUEST_REJECTED_MESSAGE,
} from "../../constants";
import { NetworkRequestState, NFTBaseData } from "../../types";
import {
  generateOrderRecipient,
  getPriceInEther,
  getImageSrc,
} from "../../utils";
import { PixelsPerInchLabel } from "../../components/PixelsPerInchLabel";
import { usePrintingPricesContext } from "../../hooks/usePrintingPricesContext";
import { PrintQualityInfoText } from "../../components/PrintQualityInfoText";
import { Layout } from "../Layout";

const OrderReviewSubsectionHeading: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Typography sx={{ opacity: 0.8 }} variant="body1" color="text.primary">
    {children}
  </Typography>
);

const OrderReview = () => {
  const [
    transactionRejectedNoticeDismissed,
    setTransactionRejectedNoticeDismissed,
  ] = useState(false);
  const [orderSubmitNoticeDismissed, setOrderSubmitNoticeDismissed] =
    useState(false);
  const [printPermissionConfirmed, setPrintPermissionConfirmed] =
    useState(false);
  const [allSalesFinalAcknowledged, setAllSalesFinalAcknowledged] =
    useState(false);
  const [showLowPixelDensityNotice, setShowLowPixelDensityNotice] =
    useState(false);
  const [
    insufficientFundsNoticeDismissed,
    setInsufficientFundsNoticeDismissed,
  ] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const {
    orderSubmitStatus,
    orderSubmitError,
    submittedPrintOrder,
    submitPrintOrder,
    clearOrderSubmitData,
  } = useOrdersContext();
  const { printingPrices } = usePrintingPricesContext();
  const { library, account } = useEthers();
  const { sendTransaction, state, resetState } = useSendTransaction({
    chainId: 1, // ethereum,
    transactionName: "WalletPrints order",
  });
  const { priceData } = useContext(EtherPriceDataContext);
  const { shippingData } = useContext(ShippingDataContext);
  const {
    clearOrderEstimateData,
    orderEstimate,
    retrievingOrderEstimate,
    checkoutId,
  } = useOrderEstimateContext();
  const { selectedPrintPreview, selectedNFT } = useProductSelection();
  const navigate = useNavigate();

  const onPrintPermissionConfirmedToggle = useCallback(() => {
    setPrintPermissionConfirmed((permissionConfirmed) => !permissionConfirmed);
  }, []);

  const onAllSalesFinalAcknowledgedToggle = useCallback(() => {
    setAllSalesFinalAcknowledged((acknowledged) => !acknowledged);
  }, []);

  const onBackClick = () => {
    navigate(-1);
  };

  const onSubmitOrderClick = useCallback(() => {
    if (!orderEstimate || !library || !account) {
      return;
    }

    setSubmitAttempted(true);

    if (!printPermissionConfirmed) {
      return;
    }

    setShowLowPixelDensityNotice(false);
    resetState();
    setTransactionRejectedNoticeDismissed(false);
    setOrderSubmitNoticeDismissed(false);
    setInsufficientFundsNoticeDismissed(false);

    const { total } = orderEstimate;

    sendTransaction({
      to: EXPECTED_ETH_DESTINATION_WALLET_ADDRESS,
      from: account,
      value: utils.parseEther(`${total}`),
    });
  }, [
    orderEstimate,
    library,
    account,
    printPermissionConfirmed,
    sendTransaction,
    resetState,
  ]);

  const onSubmitOrderClickWithLowPixelDensity = useCallback(() => {
    setSubmitAttempted(true);
    if (!printPermissionConfirmed) {
      return;
    }

    setShowLowPixelDensityNotice(true);
  }, [printPermissionConfirmed]);

  const submitPrintOrderForProcessing = useCallback(() => {
    const { transaction } = state;

    if (
      !selectedPrintPreview ||
      !selectedNFT ||
      !checkoutId ||
      !transaction ||
      !shippingData
    ) {
      return;
    }

    const { metadata } = selectedNFT;
    const { image, name } = metadata;

    const orderRecipient = generateOrderRecipient(shippingData);

    const nftImageUrl = getImageSrc(image as string);

    const nftData: NFTBaseData = {
      image: nftImageUrl,
      name: name as string,
    };

    submitPrintOrder(
      orderRecipient,
      selectedPrintPreview.variant,
      nftImageUrl,
      transaction.hash,
      checkoutId,
      nftData
    );
  }, [
    selectedPrintPreview,
    selectedNFT,
    checkoutId,
    state,
    shippingData,
    submitPrintOrder,
  ]);

  const onViewOrderDetailsClick = useCallback(() => {
    if (!submittedPrintOrder) {
      return;
    }

    navigate(`/${ROUTES.ORDERS}/${submittedPrintOrder.id}`);
  }, [navigate, submittedPrintOrder]);

  const onSubmitAnotherPrintOrderClick = useCallback(() => {
    navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`);
  }, [navigate]);

  const onOrderErrorAcknowledgement = useCallback(() => {
    navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`);
  }, [navigate]);

  const onNoticeClose = useCallback(
    (setterFn: (value: boolean) => void) =>
      (_event: Event | React.SyntheticEvent, reason: string) => {
        if (reason === "clickaway") {
          return;
        }

        setterFn(true);
      },
    []
  );

  const getSubmitButtonLabel = useCallback(() => {
    const pendingWalletTransactionApproval =
      state.status === "PendingSignature";
    const blockchainTransactionProcessing = state.status === "Mining";
    const submittingPrintOrder =
      orderSubmitStatus === NetworkRequestState.LOADING;

    if (pendingWalletTransactionApproval) {
      return "Pending Transaction Approval";
    }

    if (blockchainTransactionProcessing) {
      return "Blockchain Transaction Processing";
    }

    if (submittingPrintOrder) {
      return "Submitting Print Order";
    }

    return "Submit Order";
  }, [state.status, orderSubmitStatus]);

  useEffect(() => {
    if (
      state.status === "Success" &&
      orderSubmitStatus === NetworkRequestState.IDLE
    ) {
      submitPrintOrderForProcessing();
    }
  }, [state, orderSubmitStatus, submitPrintOrderForProcessing]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  useEffect(() => {
    if (!orderEstimate) {
      navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`);
    }
  }, [orderEstimate, navigate]);

  useEffect(() => {
    return () => {
      clearOrderSubmitData();
      clearOrderEstimateData();
    };
  }, [clearOrderSubmitData, clearOrderEstimateData]);

  const processingOrder =
    orderSubmitStatus === NetworkRequestState.LOADING ||
    state.status === "PendingSignature" ||
    state.status === "Mining";

  const orderSuccess = Boolean(
    orderSubmitStatus === NetworkRequestState.SUCCESS && submittedPrintOrder
  );
  const orderError = orderSubmitStatus === NetworkRequestState.ERROR;

  return (
    <Layout.PageContent>
      <OrderStepContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          gap={1}
          marginBottom={4}
        >
          {selectedPrintPreview && priceData && printingPrices && (
            <OrderSummaryCard
              variant={selectedPrintPreview!.variant}
              image={selectedPrintPreview!.image}
            >
              <Typography color="text.secondary">
                {getPriceInEther(
                  printingPrices[selectedPrintPreview.variant],
                  priceData.price
                )}{" "}
                ETH
              </Typography>
              <PixelsPerInchLabel ppi={selectedPrintPreview.pixelsPerInch} />
            </OrderSummaryCard>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 2,
          }}
        >
          <SectionHeader>Review Order</SectionHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 3,
            }}
          >
            {shippingData && (
              <Box>
                <OrderReviewSubsectionHeading>
                  Deliver to
                </OrderReviewSubsectionHeading>
                <Shipping data={shippingData} />
              </Box>
            )}

            {priceData &&
              orderEstimate &&
              selectedPrintPreview &&
              !retrievingOrderEstimate && (
                <Box>
                  <OrderReviewSubsectionHeading>
                    Cost breakdown
                  </OrderReviewSubsectionHeading>
                  <CostBreakdown orderEstimate={orderEstimate} />
                  <Box marginTop={5}>
                    <FormControl
                      error={submitAttempted && !printPermissionConfirmed}
                      disabled={processingOrder}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={printPermissionConfirmed}
                            onChange={onPrintPermissionConfirmedToggle}
                            name="printPermisssion"
                          />
                        }
                        label="I confirm that I have permission to print this NFT"
                      />
                      {submitAttempted && !printPermissionConfirmed && (
                        <FormHelperText>This is required</FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      error={submitAttempted && !allSalesFinalAcknowledged}
                      disabled={processingOrder}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allSalesFinalAcknowledged}
                            onChange={onAllSalesFinalAcknowledgedToggle}
                            name="allSalesFinalAcknowledged"
                          />
                        }
                        label={
                          <Typography component="span">
                            I understand that{" "}
                            <strong>all sales are final</strong>
                          </Typography>
                        }
                      />
                      {submitAttempted && !allSalesFinalAcknowledged && (
                        <FormHelperText>This is required</FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginTop={6}
                  >
                    <Button
                      startIcon={<KeyboardArrowLeft />}
                      onClick={onBackClick}
                      disabled={processingOrder}
                    >
                      Back to Shipping
                    </Button>
                    <Button
                      variant="contained"
                      onClick={
                        selectedPrintPreview.pixelsPerInch < 150
                          ? onSubmitOrderClickWithLowPixelDensity
                          : onSubmitOrderClick
                      }
                      disabled={processingOrder}
                      endIcon={
                        processingOrder ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null
                      }
                    >
                      {getSubmitButtonLabel()}
                    </Button>
                  </Box>
                </Box>
              )}
            {retrievingOrderEstimate && (
              <Box
                sx={{
                  "&:not(:last-child)": {
                    marginBottom: 2,
                  },
                }}
              >
                <Skeleton height={24} width={120} sx={{ marginBottom: 1 }} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Box marginBottom={3} />
                <Skeleton height={32} />
              </Box>
            )}
          </Box>
        </Box>
      </OrderStepContent>
      <Snackbar
        open={state.status === "Mining" && !orderSubmitNoticeDismissed}
        autoHideDuration={10000}
        onClose={onNoticeClose(setOrderSubmitNoticeDismissed)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="info">
          Placing order. This may take up to several minutes depending on
          network congestion.
        </Alert>
      </Snackbar>

      <Snackbar
        open={
          state.status === "Exception" &&
          state.errorMessage === TRANSACTION_REQUEST_REJECTED_MESSAGE &&
          !transactionRejectedNoticeDismissed
        }
        autoHideDuration={4000}
        onClose={onNoticeClose(setTransactionRejectedNoticeDismissed)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="info">The transaction request was rejected.</Alert>
      </Snackbar>
      <Snackbar
        open={
          state.status === "Exception" &&
          state.errorMessage?.includes(INSUFFICIENT_FUNDS_ERROR_STRING) &&
          !insufficientFundsNoticeDismissed
        }
        autoHideDuration={4000}
        onClose={onNoticeClose(setInsufficientFundsNoticeDismissed)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          Could not submit print order. Insufficient funds in wallet.
        </Alert>
      </Snackbar>
      <Dialog open={orderSuccess}>
        <DialogContent sx={{ minWidth: 320 }}>
          <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginBottom={3}>
            <CheckCircle fontSize="large" color="success" />
          </Box>
          <DialogContentText textAlign="center">
            Print order submitted!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmitAnotherPrintOrderClick} autoFocus>
            Order another print
          </Button>
          <Button onClick={onViewOrderDetailsClick}>View order details</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={orderError}>
        <DialogContent sx={{ minWidth: 320 }}>
          <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginBottom={3}>
            <Error fontSize="large" color="error" />
          </Box>
          <DialogContentText textAlign="center">
            {ORDER_SUBMISSION_ERROR_STRINGS[orderSubmitError] ||
              ORDER_SUBMISSION_ERROR_STRINGS.UNKNOWN_ERROR}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOrderErrorAcknowledgement}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={state.status === "Fail"}>
        <DialogContent sx={{ minWidth: 320 }}>
          <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginBottom={3}>
            <Error fontSize="large" color="error" />
          </Box>
          <DialogContentText textAlign="center">
            The blockchain transaction failed. Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOrderErrorAcknowledgement}>Go Home</Button>
          <Button onClick={onSubmitOrderClick}>Try again</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showLowPixelDensityNotice}>
        <DialogTitle>Submit low-quality print?</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            The DPI (dots per inch) for your order is{" "}
            {selectedPrintPreview?.pixelsPerInch}, below the recommended 150 DPI
            threshold.
          </Typography>
          <Typography variant="body2" fontWeight="bold" gutterBottom>
            By submitting this print order, you acknowledge &amp; accept the
            high likelihood of a low-quality print.
          </Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>What is DPI?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PrintQualityInfoText />
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => setShowLowPixelDensityNotice(false)}>
            Go back
          </Button>
          <Button onClick={onSubmitOrderClick} variant="outlined">
            Understood, submit print order
          </Button>
        </DialogActions>
      </Dialog>
    </Layout.PageContent>
  );
};

export default OrderReview;
