import React, { createContext, useCallback, useState } from "react";

import { Country, ShippingFormValues } from "../../types";
import { getAcceptedCountries } from "../../network/prints";
import { useAuthErrorHandling } from "../../hooks/useAuthErrorHandling";
import { isInvalidTokenError } from "../../utils";

export interface ShippingData {
  acceptedCountries: Country[];
  retrievingAcceptedCountries: boolean;
  acceptedCountriesRetrievalError: boolean;
  shippingData: ShippingFormValues | null;
  retrieveAcceptedCountries: () => void;
  clearError: (tryAgain: boolean) => void;
  setShippingData: (formValues: ShippingFormValues) => void;
}

const defaultContextValue: ShippingData = {
  acceptedCountries: [],
  retrievingAcceptedCountries: false,
  acceptedCountriesRetrievalError: false,
  shippingData: null,
  retrieveAcceptedCountries: () => {},
  clearError: () => {},
  setShippingData: () => {},
};

const ShippingDataContext = createContext<ShippingData>(defaultContextValue);

const ShippingDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { onInvalidTokenError } = useAuthErrorHandling();
  const [acceptedCountries, setAcceptedCountries] = useState<Country[]>([]);
  const [retrievingAcceptedCountries, setRetrievingAcceptedCountries] =
    useState<boolean>(false);
  const [acceptedCountriesRetrievalError, setAcceptedCountriesRetrievalError] =
    useState<boolean>(false);
  const [shippingData, setShippingData] = useState<ShippingFormValues | null>(
    null
  );

  const retrieveAcceptedCountries = useCallback(() => {
    setRetrievingAcceptedCountries(true);
    getAcceptedCountries()
      .then((responseData) => {
        const { result } = responseData.data;

        setAcceptedCountries(result);
        setRetrievingAcceptedCountries(false);
      })
      .catch((error) => {
        setRetrievingAcceptedCountries(false);
        setAcceptedCountriesRetrievalError(true);

        if (isInvalidTokenError(error)) {
          return onInvalidTokenError();
        }
      });
  }, [onInvalidTokenError]);

  const clearError = useCallback(
    (tryAgain = false) => {
      setAcceptedCountriesRetrievalError(false);

      if (tryAgain) {
        retrieveAcceptedCountries();
      }
    },
    [retrieveAcceptedCountries]
  );

  const contextValue: ShippingData = {
    acceptedCountries,
    retrievingAcceptedCountries,
    acceptedCountriesRetrievalError,
    retrieveAcceptedCountries,
    clearError,
    shippingData,
    setShippingData,
  };

  return (
    <ShippingDataContext.Provider value={contextValue}>
      {children}
    </ShippingDataContext.Provider>
  );
};

export default ShippingDataContext;
export { ShippingDataProvider };
