import { useCallback, useEffect, useState } from "react";
import { Controller, Control, Validate } from "react-hook-form";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { Country, ShippingFormValues, State } from "../../types";

interface AutocompleteOptionProps {
  option: Country | State;
  optionElementProps: React.HTMLAttributes<HTMLLIElement>;
}

const AutocompleteOption: React.VFC<AutocompleteOptionProps> = ({
  option,
  optionElementProps,
}) => {
  return (
    <Box component="li" {...optionElementProps} key={option.code}>
      {option.name}
    </Box>
  );
};

const getAutocompleteOptionLabel = (option: Country | State) => option.name;

const autocompleteTextFieldProps = (
  params: AutocompleteRenderInputParams,
  label: string,
  name: string
): TextFieldProps => {
  return {
    ...params,
    size: "small",
    variant: "outlined",
    label,
    inputProps: {
      ...params.inputProps,
      autoComplete: "new-password",
      name,
    },
  };
};

interface ControlledAutocompleteProps {
  control: Control<ShippingFormValues, any> | undefined;
  name: keyof ShippingFormValues;
  options: Country[] | State[];
  defaultValue: Country | State;
  label: string;
  validate?: Record<string, Validate<string>>;
  error?: string;
  disabled: boolean;
}

const ControlledAutocomplete: React.VFC<ControlledAutocompleteProps> = ({
  control,
  name,
  label,
  options,
  validate,
  defaultValue,
  error,
  disabled,
}) => {
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const getSelectedOption = useCallback(
    (countryOrStateCodeValue: string) =>
      options &&
      options.find((option) => option.code === countryOrStateCodeValue),
    [options]
  );

  useEffect(() => {
    if (defaultValue) {
      setAutocompleteInputValue(defaultValue.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      rules={validate}
      render={({ field }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getAutocompleteOptionLabel}
          renderOption={(props, option) => (
            <AutocompleteOption
              key={option.code}
              option={option}
              optionElementProps={props}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...autocompleteTextFieldProps(params, label, name)}
              error={!!error}
              helperText={error}
              value={autocompleteInputValue || ""}
            />
          )}
          {...field}
          value={getSelectedOption(field.value || "") || defaultValue}
          onChange={(_event, data) => data?.code && field.onChange(data.code)}
          inputValue={autocompleteInputValue}
          onInputChange={(_event, newInputValue) =>
            _event && setAutocompleteInputValue(newInputValue)
          }
          disabled={disabled}
          autoHighlight
          disablePortal
        />
      )}
    />
  );
};

export default ControlledAutocomplete;
