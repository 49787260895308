import { GENERAL_ERRORS, ORDER_REQUEST_ERRORS } from "../constants";
import {
  SubmitOrderResponse,
  GetCountriesResponseData,
  OrderEstimateResponseData,
  OrderRecipient,
  PosterVariantKeys,
  PrintPreviewsResponseData,
  SupportedCurrencies,
  GetPrintingPricesResponse,
  OrdersListResponse,
  NFTBaseData,
} from "../types";
import { getImageSrc } from "../utils";
import { authFetch } from "./authFetch";

const API_BASE_URL = "https://prints-api.walletprints.com";

export const generatePrintPreviews = (imageUrl: string) => {
  if (!imageUrl) {
    throw new Error("No image URL provided");
  }

  return authFetch(`${API_BASE_URL}/print-previews?image=${imageUrl}`)
    .then((response) => response.json())
    .then((responseData: unknown) => responseData as PrintPreviewsResponseData);
};

export const getAcceptedCountries = () => {
  return authFetch(`${API_BASE_URL}/countries`)
    .then((response) => response.json())
    .then((responseData: unknown) => responseData as GetCountriesResponseData);
};

export const getOrderEstimate = async (
  recipient: OrderRecipient,
  variant: PosterVariantKeys,
  imageUrl: string,
  targetCurrency: SupportedCurrencies
) => {
  if (!recipient) {
    throw new Error("No order recipient provided");
  }

  if (!variant) {
    throw new Error("No print variant provided");
  }

  if (!imageUrl) {
    throw new Error("No image URL provided");
  }

  if (!targetCurrency) {
    throw new Error("No target currency specified");
  }

  const requestBody = {
    recipient,
    variant,
    image: getImageSrc(imageUrl),
    currency: targetCurrency,
  };

  const response = await authFetch(`${API_BASE_URL}/order-estimate`, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: { "Content-Type": "application/json" },
  });

  const responseData = await response.json();

  if (response.status === 400) {
    throw new Error(responseData?.error);
  }

  return responseData as OrderEstimateResponseData;
};

export const submitPrintOrder = async (
  recipient: OrderRecipient,
  variant: PosterVariantKeys,
  imageUrl: string,
  transactionHash: string,
  checkoutId: string,
  nftData: NFTBaseData
) => {
  if (!recipient) {
    throw new Error("No order recipient provided");
  }

  if (!variant) {
    throw new Error("No print variant provided");
  }

  if (!imageUrl) {
    throw new Error("No image URL provided");
  }

  if (!transactionHash) {
    throw new Error("No transaction hash provided");
  }

  if (!checkoutId) {
    throw new Error("No checkout ID provided");
  }

  if (!nftData) {
    throw new Error("No NFT data provided");
  }

  const requestBody = {
    recipient,
    variant,
    transactionHash,
    checkoutId,
    image: imageUrl,
    nftData,
  };

  const response = await authFetch(`${API_BASE_URL}/order-print`, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: { "Content-Type": "application/json" },
  }).catch(() => {
    throw new Error(GENERAL_ERRORS.NETWORK_ERROR);
  });

  const responseData = await response.json();

  if (response.status === 500 || response.status === 400) {
    throw new Error(responseData?.error);
  }

  return (responseData as SubmitOrderResponse).data;
};

export const getPrintingPrices = async () => {
  const response = await authFetch(`${API_BASE_URL}/prices`, {
    method: "GET",
  });

  const responseData = await response.json();

  return (responseData as GetPrintingPricesResponse).data;
};

export const getOrders = async (walletAddress: string) => {
  if (!walletAddress) {
    throw new Error("No wallet address provided");
  }

  const response = await authFetch(
    `${API_BASE_URL}/user-orders?address=${walletAddress}`
  ).catch(() => {
    throw new Error(GENERAL_ERRORS.NETWORK_ERROR);
  });

  if (response.status !== 200) {
    throw new Error(ORDER_REQUEST_ERRORS.ORDER_DETAILS_RETRIEVAL_ERROR);
  }

  const responseData = await response.json();
  return (responseData as OrdersListResponse).data;
};
