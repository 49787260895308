import { AUTH_ERRORS } from "./constants";
import {
  EtherPriceData,
  NFTData,
  OrderCosts,
  OrderRecipient,
  ShippingFormValues,
} from "./types";

export const getImageSrc = (imageUri: string): string => {
  if (imageUri.startsWith("ipfs://")) {
    const imagePath = imageUri.split("//")[1];

    return `https://ipfs.io/ipfs/${imagePath}`;
  }

  return imageUri;
};

export const getPrintPreviewThumbnailStyles = (): React.CSSProperties => ({
  height: 100,
  width: 100,
  position: "relative",
});

export const getThumbnailStyles = (imageUri: string): React.CSSProperties => ({
  backgroundImage: `url(${getImageSrc(imageUri)})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: 100,
  width: 100,
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

export const getSelectedPreviewStyles = (): React.CSSProperties => ({
  height: "100%",
  width: "100%",
  maxWidth: 500,
  position: "relative",
});

export const getBase64ImageSrc = (base64ImageData: string) =>
  `data:image/jpeg;charset=utf-8;base64,${base64ImageData}`;

export const getEtherPrices = (
  priceData: EtherPriceData,
  orderEstimate: OrderCosts
): OrderCosts => {
  const { price } = priceData;
  const { subtotal, shipping, digitization, tax, vat, total } = orderEstimate;

  return {
    currency: "ETH",
    discount: 0,
    subtotal: subtotal / price,
    shipping: shipping / price,
    digitization: digitization / price,
    tax: tax / price,
    vat: vat / price,
    total: total / price,
  };
};

export const isInvalidTokenError = (error: Error) => {
  console.log("error.message: ", error.message);
  return error.message === AUTH_ERRORS.INVALID_TOKEN;
};

export const generateOrderRecipient = (
  shippingData: ShippingFormValues
): OrderRecipient => {
  const { address1, address2, city, countryCode, stateCode, zip, name } =
    shippingData;

  return {
    name,
    address1,
    address2,
    city,
    zip,
    country_code: countryCode,
    state_code: stateCode,
  };
};

export const getPriceInEther = (usdPrice: number, etherPrice: number) =>
  (usdPrice / etherPrice).toFixed(4);

export const generateNFTValueString = (nftData: NFTData) => {
  return `${nftData.metadata.name}.${nftData.id.tokenId}`;
};
