import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

import { OrderSummaryCard } from "../../components/OrderSummaryCard";
import { SectionHeader } from "../../components/SectionHeader";
import { ShippingInfoForm } from "../../components/ShippingInfoForm";
import { OrderStepContent } from "../../components/OrderStepContent";
import { useProductSelection } from "../../hooks/useProductSelection";
import { useOrderEstimateContext } from "../../hooks/useOrderEstimateContext";
import { usePrintingPricesContext } from "../../hooks/usePrintingPricesContext";
import {
  ORDER_ESTIMATE_ERRORS,
  ROUTES,
  PRINT_ORDER_ROUTES,
} from "../../constants";
import { ShippingFormValues } from "../../types";
import { generateOrderRecipient, getPriceInEther } from "../../utils";
import { EtherPriceDataContext } from "../../context";
import { PixelsPerInchLabel } from "../../components/PixelsPerInchLabel";
import { Layout } from "../Layout";

const ShippingInfoCollection = () => {
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const navigate = useNavigate();
  const { priceData } = useContext(EtherPriceDataContext);
  const { printingPrices } = usePrintingPricesContext();
  const {
    retrieveOrderEstimate,
    clearOrderEstimateRetrievalError,
    orderEstimateRetrievalError,
    retrievingOrderEstimate,
    orderEstimate,
  } = useOrderEstimateContext();
  const { selectedPrintPreview, selectedNFT } = useProductSelection();

  const onBackClick = () => {
    navigate(-1);
  };

  const onSubmit = useCallback(
    (shippingData: ShippingFormValues) => {
      if (!shippingData || !selectedPrintPreview || !selectedNFT) {
        return;
      }

      setSubmitAttempted(true);

      const { metadata } = selectedNFT;
      const { image } = metadata;

      const orderRecipient = generateOrderRecipient(shippingData);

      // metadata.image will be defined
      retrieveOrderEstimate(
        orderRecipient,
        selectedPrintPreview.variant,
        image!
      );
    },
    [selectedPrintPreview, selectedNFT, retrieveOrderEstimate]
  );

  useEffect(() => {
    if (!selectedPrintPreview || !priceData) {
      navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`);
    }
  }, [selectedPrintPreview, priceData, navigate]);

  useEffect(() => {
    if (orderEstimate && submitAttempted) {
      navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.ORDER_REVIEW}`);
    }
  }, [orderEstimate, submitAttempted, navigate]);

  useEffect(() => {
    return () => {
      clearOrderEstimateRetrievalError();
    };
  }, [clearOrderEstimateRetrievalError]);

  const invalidAddressError =
    orderEstimateRetrievalError === ORDER_ESTIMATE_ERRORS.INVALID_ADDRESS;

  return (
    <Layout.PageContent>
      <OrderStepContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          gap={1}
          marginBottom={4}
        >
          {printingPrices && selectedPrintPreview && priceData && (
            <OrderSummaryCard
              variant={selectedPrintPreview.variant}
              image={selectedPrintPreview.image}
            >
              <Typography color="text.secondary">
                {getPriceInEther(
                  printingPrices[selectedPrintPreview.variant],
                  priceData.price
                )}{" "}
                ETH
              </Typography>
              <PixelsPerInchLabel ppi={selectedPrintPreview.pixelsPerInch} />
            </OrderSummaryCard>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <SectionHeader>Shipping Information</SectionHeader>
          <ShippingInfoForm onSubmit={onSubmit}>
            <Button startIcon={<KeyboardArrowLeft />} onClick={onBackClick}>
              Back to Select
            </Button>
          </ShippingInfoForm>
        </Box>
      </OrderStepContent>
      <Snackbar
        open={!retrievingOrderEstimate && invalidAddressError}
        autoHideDuration={6000}
        onClose={clearOrderEstimateRetrievalError}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">Invalid or unsupported shipping address</Alert>
      </Snackbar>
    </Layout.PageContent>
  );
};

export default ShippingInfoCollection;
