import React, { createContext, useCallback, useState } from "react";
import { AUTH_TOKEN_KEY } from "../../constants";
import {
  getUserExists,
  getUserNonce as getUserNonceRequest,
  createUser,
  authUser as verifySignature,
} from "../../network/user";
import { NetworkRequestState } from "../../types";

interface ContextValue {
  token: string | null;
  authenticatingUserStatus: NetworkRequestState;
  authUser: (walletAddress: string, signature: string) => void;
  getUserNonce: (
    walletAddress: string,
    recaptchaToken: string
  ) => Promise<string>;
  clearToken: () => void;
}

const AuthContext = createContext<ContextValue | undefined>(undefined);

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [jwt, setJwt] = useState<ContextValue["token"]>(null);
  const [authenticatingUserStatus, setAuthenticatingUserStatus] = useState(
    NetworkRequestState.IDLE
  );

  const clearToken = useCallback(() => {
    setJwt(null);
  }, []);

  const getUserNonce = useCallback(
    async (walletAddress: string, recaptchaToken: string) => {
      const userExists = await getUserExists(walletAddress);

      if (userExists) {
        const nonce = await getUserNonceRequest(walletAddress);

        return nonce;
      }

      const user = await createUser(walletAddress, recaptchaToken);

      return user.nonce;
    },
    []
  );

  const authUser = useCallback((walletAddress: string, signature: string) => {
    setAuthenticatingUserStatus(NetworkRequestState.LOADING);
    verifySignature(walletAddress, signature)
      .then((jwt) => {
        setJwt(jwt);
        localStorage.setItem(AUTH_TOKEN_KEY, jwt);
        setAuthenticatingUserStatus(NetworkRequestState.SUCCESS);
      })
      .catch(() => {
        setAuthenticatingUserStatus(NetworkRequestState.ERROR);
      });
  }, []);

  const contextValue: ContextValue = {
    token: jwt,
    authenticatingUserStatus,
    authUser,
    getUserNonce,
    clearToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
export { AuthContextProvider };
