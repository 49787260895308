import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { OrderCosts } from "../../types";

import LineItem from "./LineItem";

const LINE_ITEM_TEXT_PROPS: TypographyProps = {
  variant: "body1",
  color: "text.primary",
};

interface CostBreakdownProps {
  orderEstimate: OrderCosts;
}

const CostBreakdown: React.VFC<CostBreakdownProps> = ({ orderEstimate }) => {
  return (
    <Box>
      <Box
        sx={{
          "&:not(:last-child)": {
            marginBottom: 2,
          },
        }}
      >
        <LineItem>
          <Typography {...LINE_ITEM_TEXT_PROPS}>Subtotal</Typography>
          <Typography {...LINE_ITEM_TEXT_PROPS} textAlign="right">
            {orderEstimate.subtotal.toFixed(4)} ETH
          </Typography>
        </LineItem>
        <LineItem>
          <Typography {...LINE_ITEM_TEXT_PROPS}>Shipping</Typography>
          <Typography {...LINE_ITEM_TEXT_PROPS} textAlign="right">
            {orderEstimate.shipping.toFixed(4)} ETH
          </Typography>
        </LineItem>
        {orderEstimate.tax > 0 && (
          <LineItem>
            <Typography {...LINE_ITEM_TEXT_PROPS}>Tax</Typography>
            <Typography {...LINE_ITEM_TEXT_PROPS} textAlign="right">
              {orderEstimate.tax.toFixed(4)} ETH
            </Typography>
          </LineItem>
        )}
        {orderEstimate.vat > 0 && (
          <LineItem>
            <Typography {...LINE_ITEM_TEXT_PROPS}>Value-added Tax</Typography>
            <Typography {...LINE_ITEM_TEXT_PROPS} textAlign="right">
              {orderEstimate.vat.toFixed(4)} ETH
            </Typography>
          </LineItem>
        )}
      </Box>
      <Box marginBottom={3} />
      <LineItem>
        <Typography variant="h5" color="text.primary">
          Total
        </Typography>
        <Typography variant="h5" textAlign="right" color="text.primary">
          {orderEstimate.total.toFixed(4)} ETH
        </Typography>
      </LineItem>
    </Box>
  );
};

export default CostBreakdown;
