import { useContext } from "react";

import { OrderEstimateContext } from "../../context";

const useOrderEstimateContext = () => {
  const contextValue = useContext(OrderEstimateContext);

  if (!contextValue) {
    throw new Error(
      "Component must be wrapped in OrderEstimateContextProvider"
    );
  }

  return contextValue;
};

export default useOrderEstimateContext;
