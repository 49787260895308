import { createRoot } from "react-dom/client";

import { ThemeProvider } from "@mui/material/styles";
import { DAppProvider, Config } from "@usedapp/core";
import { darkTheme } from "./theme";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  AuthContextProvider,
  ProductSelectionProvider,
  ShippingDataProvider,
  EtherPriceDataProvider,
  OrdersContextProvider,
  OrderEstimateContextProvider,
  PrintingPricesContextProvider,
} from "./context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  OrderDetails,
  Orders,
  OrdersList,
  ShippingInfoCollection,
  OrderReview,
  Print,
  Landing,
  PrintOptionSelect,
  NFTSelection,
  PrintSizeSelection,
} from "./modules";
import { ROUTES, PRINT_ORDER_ROUTES } from "./constants";
import "./index.css";

const dappConfig: Config = {};

const { ORDERS, PRINT, LANDING } = ROUTES;

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <DAppProvider config={dappConfig}>
      <AuthContextProvider>
        <ThemeProvider theme={darkTheme}>
          <EtherPriceDataProvider>
            <PrintingPricesContextProvider>
              <ProductSelectionProvider>
                <ShippingDataProvider>
                  <OrderEstimateContextProvider>
                    <OrdersContextProvider>
                      <Routes>
                        <Route path="/" element={<App />}>
                          <Route path={LANDING} element={<Landing />} />
                          <Route path={ORDERS} element={<Orders />}>
                            <Route path={""} element={<OrdersList />} />
                            <Route
                              path={":orderId"}
                              element={<OrderDetails />}
                            />
                          </Route>
                          <Route path={PRINT} element={<Print />}>
                            <Route path="" element={<PrintOptionSelect />}>
                              <Route
                                path={PRINT_ORDER_ROUTES.NFT_SELECT}
                                element={<NFTSelection />}
                              />
                              <Route
                                path={PRINT_ORDER_ROUTES.SIZE_SELECT}
                                element={<PrintSizeSelection />}
                              />
                            </Route>
                            <Route
                              path={PRINT_ORDER_ROUTES.SHIPPING_INFO_COLLECTION}
                              element={<ShippingInfoCollection />}
                            />
                            <Route
                              path={PRINT_ORDER_ROUTES.ORDER_REVIEW}
                              element={<OrderReview />}
                            />
                          </Route>
                        </Route>
                      </Routes>
                    </OrdersContextProvider>
                  </OrderEstimateContextProvider>
                </ShippingDataProvider>
              </ProductSelectionProvider>
            </PrintingPricesContextProvider>
          </EtherPriceDataProvider>
        </ThemeProvider>
      </AuthContextProvider>
    </DAppProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
