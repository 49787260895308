import { useCallback, useState } from "react";

import { generatePrintPreviews } from "../../network/prints";
import { NetworkRequestState, PrintPreview } from "../../types";
import { isInvalidTokenError } from "../../utils";
import { useAuthErrorHandling } from "../useAuthErrorHandling";

interface UsePrintPreviews {
  printPreviews: PrintPreview[];
  getPrintPreviews: (imageUrl: string) => void;
  setPrintPreviews: (printPreviews: PrintPreview[]) => void;
  printPreviewsRequestStatus: NetworkRequestState;
  clearPrintPreviewsRequestStatus: () => void;
}

function usePrintPreviews(): UsePrintPreviews {
  const { onInvalidTokenError } = useAuthErrorHandling();
  const [printPreviews, setPrintPreviews] = useState<PrintPreview[]>([]);
  const [printPreviewsRequestStatus, setPrintPreviewsRequestStatus] = useState(
    NetworkRequestState.IDLE
  );

  const getPrintPreviews = useCallback(
    (imageUrl: string) => {
      setPrintPreviewsRequestStatus(NetworkRequestState.LOADING);
      setPrintPreviews([]);
      generatePrintPreviews(imageUrl)
        .then((data) => setPrintPreviews(data.data))
        .then(() => setPrintPreviewsRequestStatus(NetworkRequestState.SUCCESS))
        .catch((error) => {
          if (isInvalidTokenError(error)) {
            setPrintPreviews([]);
            onInvalidTokenError();
          }

          setPrintPreviewsRequestStatus(NetworkRequestState.ERROR);

          console.error(error);
        });
    },
    [onInvalidTokenError]
  );

  const clearPrintPreviewsRequestStatus = useCallback(() => {
    setPrintPreviewsRequestStatus(NetworkRequestState.IDLE);
  }, []);

  return {
    printPreviews,
    printPreviewsRequestStatus,
    getPrintPreviews,
    setPrintPreviews,
    clearPrintPreviewsRequestStatus,
  };
}

export default usePrintPreviews;
