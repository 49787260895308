import { useCallback, useContext, useEffect, useState } from "react";
import { Link, Outlet as RouterOutlet, useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { ImageSelect, ImageSelectOption } from "../../components/ImageSelect";
import { ImageThumbnail } from "../../components/ImageThumbnail";
import { SectionHeader } from "../../components/SectionHeader";
import {
  CENTERED_FLEX_COLUMN_STYLES,
  POSTER_VARIANT_LABELS,
} from "../../constants";
import { useProductSelection } from "../../hooks/useProductSelection";
import {
  generateNFTValueString,
  getBase64ImageSrc,
  getPriceInEther,
  getSelectedPreviewStyles,
} from "../../utils";
import { ROUTES, PRINT_ORDER_ROUTES } from "../../constants";
import { NetworkRequestState } from "../../types";
import { usePrintingPricesContext } from "../../hooks/usePrintingPricesContext";
import { EtherPriceDataContext } from "../../context";
import { PixelsPerInchLabel } from "../../components/PixelsPerInchLabel";
import { Layout } from "../Layout";
import { useAppDataStatus } from "../../hooks/useAppDataStatus";

const NFTSelect = ImageSelect;
const NFTSelectOption = ImageSelectOption;
const NFTThumbnail = ImageThumbnail;

export const NFTSelection = () => {
  const navigate = useNavigate();
  const {
    ownedNFTsData,
    selectedNFT,
    printPreviewsRequestStatus,
    retrievingOwnedNFTs,
    onNFTSelect,
  } = useProductSelection();

  const { retrievingAppData, appDataRetrievalFailed } = useAppDataStatus();

  const onNFTSelection = useCallback(
    (tokenValue: string) => {
      if (!selectedNFT || tokenValue !== generateNFTValueString(selectedNFT)) {
        onNFTSelect(tokenValue);
      }
      navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.SIZE_SELECT}`);
    },
    [selectedNFT, onNFTSelect, navigate]
  );

  const ownedNFTsWithImages = ownedNFTsData.filter(
    (ownedNFT) => ownedNFT.metadata.image
  );

  const generatingPrintPreviews =
    printPreviewsRequestStatus === NetworkRequestState.LOADING;

  const applicationControlsDisabled =
    generatingPrintPreviews || retrievingAppData || appDataRetrievalFailed;

  return (
    <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginTop={4} gap="64px">
      {!retrievingOwnedNFTs && ownedNFTsWithImages.length === 0 && (
        <SectionHeader>No printable NFTs found</SectionHeader>
      )}
      {ownedNFTsWithImages.length > 0 && (
        <Box sx={CENTERED_FLEX_COLUMN_STYLES}>
          <SectionHeader>Select NFT to Print</SectionHeader>
          <NFTSelect
            value={selectedNFT?.id.tokenId || ""}
            disabled={applicationControlsDisabled}
            onChange={onNFTSelection}
          >
            {ownedNFTsWithImages.map((ownedNFTData) => (
              <NFTSelectOption
                key={generateNFTValueString(ownedNFTData)}
                value={generateNFTValueString(ownedNFTData)}
                data-contract-address={ownedNFTData.contract.address}
                data-id={ownedNFTData.id.tokenId}
                data-title={ownedNFTData.title}
              >
                {ownedNFTData.media[0]?.thumbnail && (
                  <NFTThumbnail imageUri={ownedNFTData.media[0].thumbnail} />
                )}
              </NFTSelectOption>
            ))}
          </NFTSelect>
        </Box>
      )}
      {retrievingOwnedNFTs && (
        <>
          {ownedNFTsWithImages.length === 0 && (
            <SectionHeader>Retrieving your collection</SectionHeader>
          )}
          <CircularProgress />
        </>
      )}
      <Box />
    </Box>
  );
};

export const PrintSizeSelection = () => {
  const [previewsFailureNoticeDismissed, setPreviewsFailureNoticeDismissed] =
    useState(false);

  const navigate = useNavigate();
  const {
    printPreviews,
    selectedPrintPreview,
    printPreviewsRequestStatus,
    onPrintPreviewSelect,
  } = useProductSelection();

  const { priceData } = useContext(EtherPriceDataContext);

  const { printingPrices } = usePrintingPricesContext();

  const onShippingClick = useCallback(() => {
    navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.SHIPPING_INFO_COLLECTION}`);
  }, [navigate]);

  const onPrintPreviewGenerationFailureNoticeClose = useCallback(
    (_event: Event | React.SyntheticEvent, reason: string) => {
      if (reason === "clickaway") {
        return;
      }

      setPreviewsFailureNoticeDismissed(true);
    },
    []
  );

  useEffect(() => {
    setPreviewsFailureNoticeDismissed(false);
  }, [printPreviewsRequestStatus]);

  const generatingPrintPreviews =
    printPreviewsRequestStatus === NetworkRequestState.LOADING;

  const printPreviewsGenerationRequestFailed =
    printPreviewsRequestStatus === NetworkRequestState.ERROR;

  const backButton = (
    <Button
      disabled={generatingPrintPreviews}
      startIcon={<KeyboardArrowLeft />}
    >
      <Typography variant="h6">NFT Collection</Typography>
    </Button>
  );

  return (
    <>
      <Box marginBottom={4}>
        {generatingPrintPreviews ? (
          backButton
        ) : (
          <Link
            to={`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`}
            style={{ textDecoration: "none" }}
          >
            {backButton}
          </Link>
        )}
      </Box>
      {generatingPrintPreviews && (
        <Box sx={{ ...CENTERED_FLEX_COLUMN_STYLES }}>
          <SectionHeader>Generating print previews...</SectionHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              maxWidth: 500,
              minHeight: 500,
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      )}
      {printPreviews.length > 0 && (
        <Box sx={CENTERED_FLEX_COLUMN_STYLES}>
          <SectionHeader>Select Print Size</SectionHeader>
          <Box marginBottom={3}>
            <ToggleButtonGroup
              size="large"
              value={selectedPrintPreview?.variant || ""}
            >
              {printPreviews.map((printPreview) => (
                <ToggleButton
                  key={printPreview.variant}
                  value={printPreview.variant}
                  onClick={() => onPrintPreviewSelect(printPreview.variant)}
                >
                  {POSTER_VARIANT_LABELS[printPreview.variant]}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          {selectedPrintPreview && (
            <Box marginBottom={3}>
              <img
                alt="Selected print preview"
                src={getBase64ImageSrc(selectedPrintPreview.image)}
                style={getSelectedPreviewStyles()}
              />
              {printingPrices && priceData && printingPrices && (
                <Box flexGrow={1} sx={{ marginY: 3 }}>
                  <Typography
                    color="text.primary"
                    variant="h4"
                    textAlign="right"
                    fontWeight="bold"
                  >
                    {getPriceInEther(
                      printingPrices[selectedPrintPreview.variant],
                      priceData.price
                    )}{" "}
                    ETH
                  </Typography>
                  <Typography textAlign="right" color="text.secondary">
                    + tax and shipping
                  </Typography>
                  <PixelsPerInchLabel
                    ppi={selectedPrintPreview.pixelsPerInch}
                    textAlign="right"
                    flexGrow="1"
                  />
                  <Box sx={{ marginTop: 2 }}>
                    <Typography
                      textAlign="center"
                      color="text.secondary"
                      fontStyle="italic"
                      fontWeight="bold"
                    >
                      We do our best to provide an accurate print preview.
                    </Typography>
                    <Typography
                      textAlign="center"
                      color="text.secondary"
                      fontStyle="italic"
                      fontWeight="bold"
                    >
                      Final print placement may vary.
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                marginTop={4}
              >
                <Button
                  variant="outlined"
                  onClick={onShippingClick}
                  endIcon={<KeyboardArrowRight />}
                >
                  Shipping
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Snackbar
        open={
          printPreviewsGenerationRequestFailed &&
          !previewsFailureNoticeDismissed
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={onPrintPreviewGenerationFailureNoticeClose}
      >
        <Alert severity="error">Could not generate print previews.</Alert>
      </Snackbar>
    </>
  );
};

const PrintOptionSelect: React.FC = () => {
  const { clearPrintPreviewsRequestStatus } = useProductSelection();

  useEffect(() => {
    return () => {
      clearPrintPreviewsRequestStatus();
    };
  }, [clearPrintPreviewsRequestStatus]);

  return (
    <Layout.PageContent>
      <RouterOutlet />
    </Layout.PageContent>
  );
};

export default PrintOptionSelect;
