import { createContext, useCallback, useState } from "react";

import { getPrintingPrices as getPrintingPricesRequest } from "../../network/prints";
import { NetworkRequestState, PrintingPrices } from "../../types";

interface ContextValue {
  printingPrices: PrintingPrices | null;
  getPrintingPrices: () => void;
  clearPrintingPricesRequestStatus: () => void;
  printingPricesRequestStatus: NetworkRequestState;
}

const PrintingPricesContext = createContext<ContextValue | undefined>(
  undefined
);

const PrintingPricesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [printingPrices, setPrintingPrices] = useState<PrintingPrices | null>(
    null
  );
  const [printingPricesRequestStatus, setPrintingPricesRequestStatus] =
    useState<NetworkRequestState>(NetworkRequestState.IDLE);

  const getPrintingPrices = useCallback(() => {
    setPrintingPricesRequestStatus(NetworkRequestState.LOADING);

    getPrintingPricesRequest()
      .then((printingPrices) => {
        setPrintingPrices(printingPrices);

        setPrintingPricesRequestStatus(NetworkRequestState.SUCCESS);
      })
      .catch(() => {
        setPrintingPricesRequestStatus(NetworkRequestState.ERROR);
      });
  }, []);

  const clearPrintingPricesRequestStatus = useCallback(() => {
    setPrintingPricesRequestStatus(NetworkRequestState.IDLE);
  }, []);

  const contextValue: ContextValue = {
    printingPrices,
    printingPricesRequestStatus,
    getPrintingPrices,
    clearPrintingPricesRequestStatus,
  };

  return (
    <PrintingPricesContext.Provider value={contextValue}>
      {children}
    </PrintingPricesContext.Provider>
  );
};

export default PrintingPricesContext;
export { PrintingPricesContextProvider };
