import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { DiscordIcon } from "../../components/DiscordIcon";
import {
  DESKTOP_LOGO_URL,
  DISCORD_INVITE_LINK,
  MOBILE_LOGO_URL,
} from "../../constants";

export const DESKTOP_LEFT_GAP = 8;
export const DESKTOP_LEFT_WIDTH_PX = 150;
export const MOBILE_NAV_WIDTH = "fit-content";

const getImageStyles = (mobile: boolean): React.CSSProperties => {
  return {
    width: !mobile ? `${DESKTOP_LEFT_WIDTH_PX}px` : "auto",
    maxHeight: mobile ? 65 : undefined,
  };
};

const Header = styled.header`
  margin: 50px 0;
  display: inline-block;
  width: 100%;
`;

const DesktopNav = styled.nav`
  min-width: ${DESKTOP_LEFT_WIDTH_PX}px;
  width: ${DESKTOP_LEFT_WIDTH_PX}px;
`;

const MobileNav = styled.nav`
  min-width: ${MOBILE_NAV_WIDTH};
  position: sticky;
  bottom: 24px;
  margin: 32px auto 0px;
  align-items: center;
  justify-content: center;
`;

interface NavListProps {
  children: React.ReactNode;
  mobile?: boolean;
}

export const NavRoutesList: React.FC<NavListProps> = styled.ul<NavListProps>`
  width: fit-content;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.mobile
      ? `box-shadow: rgb(0 0 0 / 7%) 0px 2.8px 2.2px, rgb(0 0 0 / 9%) 0px 6.7px 5.3px,
    rgb(0 0 0 / 12%) 0px 12.5px 10px, rgb(0 0 0 / 14%) 0px 22.3px 17.9px,
    rgb(0 0 0 / 17%) 0px 41.8px 33.4px, rgb(0 0 0 / 24%) 0px 100px 80px;`
      : ""}
`;

interface NavRoutesItemProps {
  selected: boolean;
  mobile?: boolean;
  children: React.ReactNode;
}

export const NavRoutesItem: React.FC<NavRoutesItemProps> = styled.li<NavRoutesItemProps>`
  &:not(:last-child) {
    margin-bottom: ${(props) => (props.mobile ? "0px" : "16px")};
  }

  min-width: 150px;
  height: 107px;

  ${(props) => `
    & * {
      color: ${props.selected ? "#000" : "#fff"} !important;
    }
    background: ${props.selected ? "#fff" : "#000"};
  `}

  & svg {
    ${(props) => `fill: ${props.selected ? "#000" : "#fff"}`};
  }
`;

export const NavRoutesLink = styled(Link)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
`;

interface AppHeaderProps {
  label: string;
  mobile?: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({ label, mobile }) => {
  return (
    <Header>
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          justifyContent={mobile ? "space-between" : "unset"}
          gap={mobile ? DESKTOP_LEFT_GAP / 2 : DESKTOP_LEFT_GAP}
        >
          <img
            src={mobile ? MOBILE_LOGO_URL : DESKTOP_LOGO_URL}
            style={getImageStyles(!!mobile)}
            alt="Wallet Prints"
          />
          <Typography variant={mobile ? "h4" : "h3"}>{label}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton size="medium" href={DISCORD_INVITE_LINK} target="_blank">
            <DiscordIcon />
          </IconButton>
        </Box>
      </Box>
    </Header>
  );
};

const PageContent: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box width="100%">{children}</Box>
);

const Layout = {
  Header: AppHeader,
  DesktopNav,
  MobileNav,
  PageContent,
};

export default Layout;
