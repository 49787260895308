import Typography from "@mui/material/Typography";
import React from "react";

const SectionHeader: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Typography variant="h5" color="text.primary" gutterBottom>
    {children}
  </Typography>
);

export default SectionHeader;
