import { useCallback, useEffect, useState } from "react";

import { GetOwnedNFTsReturn, NFTData, OwnedNFT } from "../../types";
import { getNFTs, getNFTMetadata } from "../../network/nft";
import { useAuthContext } from "../useAuthContext";
import { useAuthErrorHandling } from "../useAuthErrorHandling";
import { isInvalidTokenError } from "../../utils";

interface UseOwnedNFTs {
  setOwner: (owner: string) => void;
  owner: string;
  ownedNFTsData: NFTData[];
  retrievingOwnedNFTs: boolean;
  retrieveAdditionalNFTs: () => void;
}

function useOwnedNFTs(): UseOwnedNFTs {
  const { token } = useAuthContext();
  const { onInvalidTokenError } = useAuthErrorHandling();

  const [owner, setOwner] = useState<string>("");
  const [pageKey, setPageKey] = useState("");
  const [ownedNFTsData, setOwnedNFTsData] = useState<NFTData[]>([]);
  const [retrievingOwnedNFTs, setRetrievingOwnedNFTs] =
    useState<boolean>(false);

  const onNFTDataRetrieved = useCallback(
    (data: NFTData[]) => {
      const supportedImageFormats = ["jpg", "jpeg", "png", "svg"];
      const nftIsImage = (nftData: NFTData) =>
        supportedImageFormats.includes(nftData.media[0].format);

      setRetrievingOwnedNFTs(false);
      setOwnedNFTsData((ownedNFTs) => [
        ...ownedNFTs,
        ...data.filter(nftIsImage),
      ]);
    },
    [setRetrievingOwnedNFTs, setOwnedNFTsData]
  );

  const processInitialGetNFTsResponse = useCallback(
    (data: GetOwnedNFTsReturn) => {
      setPageKey(data.pageKey);

      return data.ownedNfts;
    },
    []
  );

  const onDetailedNFTDataRetrievalFailed = useCallback(
    (error: Error) => {
      if (isInvalidTokenError(error)) {
        onInvalidTokenError();

        return;
      }

      setRetrievingOwnedNFTs(false);

      console.error(error);
    },
    [onInvalidTokenError]
  );

  const getDetailedDataForNFTs = useCallback(
    (ownedNFTs: OwnedNFT[]) =>
      Promise.all(
        ownedNFTs.map((ownedNFT) =>
          getNFTMetadata(ownedNFT.contract.address, ownedNFT.id.tokenId)
        )
      )
        .then(onNFTDataRetrieved)
        .catch(onDetailedNFTDataRetrievalFailed),
    [onNFTDataRetrieved, onDetailedNFTDataRetrievalFailed]
  );

  const retrieveAdditionalNFTs = useCallback(() => {
    if (!pageKey || !owner) {
      return;
    }

    setRetrievingOwnedNFTs(true);
    getNFTs(owner, pageKey)
      .then(processInitialGetNFTsResponse)
      .then(getDetailedDataForNFTs);
  }, [pageKey, owner, processInitialGetNFTsResponse, getDetailedDataForNFTs]);

  useEffect(() => {
    if (owner && token) {
      setRetrievingOwnedNFTs(true);
      getNFTs(owner)
        .then(processInitialGetNFTsResponse)
        .then(getDetailedDataForNFTs);
    }
  }, [
    onInvalidTokenError,
    onNFTDataRetrieved,
    processInitialGetNFTsResponse,
    getDetailedDataForNFTs,
    owner,
    token,
  ]);

  return {
    owner,
    setOwner,
    ownedNFTsData,
    retrievingOwnedNFTs,
    retrieveAdditionalNFTs,
  };
}

export default useOwnedNFTs;
