import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ShippingFormValues } from "../../types";

interface ShippingProps {
  data: ShippingFormValues;
}

const Shipping: React.VFC<ShippingProps> = ({ data }) => {
  const { name, address1, address2, city, stateCode, countryCode, zip } = data;

  const typographyProps = {
    color: "text.primary",
  };

  return (
    <Box>
      <Typography {...typographyProps}>{name}</Typography>
      <Typography {...typographyProps}>{address1}</Typography>
      {address2 && <Typography {...typographyProps}>{address2}</Typography>}
      <Box flexDirection="row">
        <Typography {...typographyProps}>
          {`${city}, `}
          {stateCode && `${stateCode}, `}
          {countryCode}
        </Typography>
        <Typography {...typographyProps}>{zip}</Typography>
      </Box>
    </Box>
  );
};

export default Shipping;
