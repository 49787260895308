import { useContext } from "react";

import { ProductSelectionContext, ProductSelection } from "../../context";

function useProductSelection(): ProductSelection {
  const productSelection = useContext(ProductSelectionContext);

  if (!productSelection) {
    throw new Error(
      "useProductSelection must be used within a ProductSelectionProvider"
    );
  }

  return productSelection;
}

export default useProductSelection;
