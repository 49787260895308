import { useContext } from "react";

import { PrintingPricesContext } from "../../context";

const usePrintingPricesContext = () => {
  const contextValue = useContext(PrintingPricesContext);

  if (!contextValue) {
    throw new Error(
      "Component must be wrapped in PrintingPricesContextProvider"
    );
  }

  return contextValue;
};

export default usePrintingPricesContext;
