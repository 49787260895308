import { useCallback } from "react";
import { useEthers } from "@usedapp/core";

import { useAuthContext } from "../useAuthContext";

const useAuthErrorHandling = () => {
  const { clearToken } = useAuthContext();
  const { deactivate } = useEthers();

  const onInvalidTokenError = useCallback(() => {
    clearToken();
    deactivate();
  }, [clearToken, deactivate]);

  return { onInvalidTokenError };
};

export default useAuthErrorHandling;
