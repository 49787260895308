import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { POSTER_VARIANT_LABELS, PRODUCT_LABELS } from "../../constants";
import { PosterVariantKeys } from "../../types";
import { getBase64ImageSrc } from "../../utils";

interface OrderSummaryCardProps {
  image: string;
  variant: PosterVariantKeys;
  children: React.ReactNode;
}

const OrderSummaryCard: React.FC<OrderSummaryCardProps> = ({
  variant,
  image,
  children,
}) => {
  const variantLabel = POSTER_VARIANT_LABELS[variant];

  return (
    <Card sx={{ display: "flex" }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={getBase64ImageSrc(image)}
      />
      <CardContent>
        <Typography variant="h5">{PRODUCT_LABELS.FRAMED_POSTER}</Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {variantLabel}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default OrderSummaryCard;
