import { createTheme, experimental_sx as sx } from "@mui/material/styles";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ["Inconsolata", "consolas"].join(","),
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        outlined: sx({
          transition: "background 0ms",
          border: "1px solid #fff",
          ":focus": {
            background: "#fff",
            color: "#000",
          },
        }),
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: "transparent",
          border: "1px solid #424242",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: sx({
          background: "#101010",
        }),
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: sx({
          background: "#050505",
        }),
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: sx({
          color: "text.primary",
        }),
        gutterBottom: {
          marginBottom: "1.5em",
        },
      },
    },
  },
});
