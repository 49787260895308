import { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

import LineItem from "../OrderReview/LineItem";
import Shipping from "../OrderReview/Shipping";
import { OrderName, OrderDates } from "./OrdersList";

import { useOrdersContext } from "../../hooks/useOrdersContext";
import {
  CENTERED_FLEX_COLUMN_STYLES,
  ORDER_STATUS_STRINGS,
  ROUTES,
} from "../../constants";
import { NetworkRequestState, OrderDetails } from "../../types";
import { useEthers } from "@usedapp/core";
import { SectionHeader } from "../../components/SectionHeader";
import { DataRetrievalError } from "../../components/DataRetrievalError";

interface OrderDetailsCardProps {
  order: OrderDetails;
}

const OrderDetailsCard: React.FC<OrderDetailsCardProps> = ({ order }) => {
  const [orderCostsAnchor, setOrderCostsAnchor] =
    useState<HTMLHeadingElement | null>(null);

  const displayCostBreakdown = useCallback(
    (event: React.MouseEvent<HTMLHeadingElement>) => {
      setOrderCostsAnchor(event.currentTarget);
    },
    []
  );

  const dismissCostBreakdown = useCallback(() => {
    setOrderCostsAnchor(null);
  }, []);

  const shippingData = {
    name: order.recipient.name || "",
    address1: order.recipient.address1,
    address2: order.recipient.address2,
    city: order.recipient.city,
    stateCode: order.recipient.state_code,
    countryCode: order.recipient.country_code,
    zip: order.recipient.zip,
  };

  const popoverOpen = Boolean(orderCostsAnchor);

  return (
    <>
      <Card sx={{ maxWidth: "600px", width: "100%" }}>
        <CardMedia
          component="img"
          sx={{
            maxWidth: 600,
            width: "100%",
            height: 500,
            backgroundImage: `url(${order.printPreviewUrl})`,
            objectFit: "contain",
          }}
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box>
              <OrderName variant={order.variant} nftData={order.nftData} />
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  borderBottom: "2px dashed",
                  cursor: "pointer",
                }}
                onClick={displayCostBreakdown}
                gutterBottom
              >
                {order.costs.total} {order.costs.currency}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box marginRight={6}>
              <Typography variant="body2" color="text.secondary">
                Deliver to
              </Typography>
              <Box marginBottom={4}>
                <Shipping data={shippingData} />
              </Box>
            </Box>
            <Box>
              <Typography variant="body1" color="text.secondary">
                Status
              </Typography>
              <Typography variant="h6">
                {ORDER_STATUS_STRINGS[order.status]}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box marginRight={6} marginTop={4}>
              <OrderDates created={order.created} updated={order.updated} />
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop={2}>
              {order.trackingUrl && (
                <a
                  href={order.trackingUrl}
                  target="_blank"
                  style={{ textDecoration: "none", display: "inherit" }}
                  rel="noreferrer"
                >
                  <Button variant="contained">Track shipment</Button>
                </a>
              )}
              {!order.trackingUrl && (
                <Button variant="contained" disabled>
                  Shipment tracking not yet available
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Popover
        open={popoverOpen}
        onClose={dismissCostBreakdown}
        anchorEl={orderCostsAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        sx={{ marginTop: 0.5 }}
      >
        <Box sx={{ width: "200px", padding: 2 }}>
          <LineItem>
            <Typography>Subtotal</Typography>
            <Typography>{order.costs.subtotal} ETH</Typography>
          </LineItem>
          <LineItem>
            <Typography>Shipping</Typography>
            <Typography>{order.costs.shipping} ETH</Typography>
          </LineItem>
          {Boolean(order.costs.tax) && (
            <LineItem>
              <Typography>Tax</Typography>
              <Typography>{order.costs.tax} ETH</Typography>
            </LineItem>
          )}

          {Boolean(order.costs.vat) && (
            <LineItem>
              <Typography>VAT</Typography>
              <Typography>{order.costs.vat} ETH</Typography>
            </LineItem>
          )}
        </Box>
      </Popover>
    </>
  );
};

const OrderDetailsRoute = () => {
  const { account } = useEthers();
  const { orderId } = useParams();

  const { orders, ordersRetrievalStatus, getPrintOrders } = useOrdersContext();

  const order = orders.find(({ id }) => id === orderId);

  const onOrderRetrievalRetry = useCallback(() => {
    if (!account) {
      return;
    }

    getPrintOrders(account);
  }, [getPrintOrders, account]);

  return (
    <>
      <Box>
        <Box marginBottom={4}>
          <Link to={`/${ROUTES.ORDERS}`} style={{ textDecoration: "none" }}>
            <Button startIcon={<KeyboardArrowLeft />}>
              <Typography variant="h6">All orders</Typography>
            </Button>
          </Link>
        </Box>
        {ordersRetrievalStatus === NetworkRequestState.LOADING && (
          <Box sx={CENTERED_FLEX_COLUMN_STYLES}>
            <SectionHeader>Retrieving order details...</SectionHeader>
          </Box>
        )}
        {ordersRetrievalStatus === NetworkRequestState.SUCCESS && order && (
          <>
            <Box display="flex" justifyContent="center">
              <Typography variant="h5">{order.nftData.name} print</Typography>
            </Box>

            <Box display="flex" justifyContent="center" marginTop={2}>
              <OrderDetailsCard order={order} />
            </Box>
          </>
        )}
        {(ordersRetrievalStatus === NetworkRequestState.ERROR ||
          (ordersRetrievalStatus === NetworkRequestState.SUCCESS &&
            !order)) && (
          <DataRetrievalError onRetry={onOrderRetrievalRetry}>
            Could not retrieve order
          </DataRetrievalError>
        )}
      </Box>
    </>
  );
};

export default OrderDetailsRoute;
