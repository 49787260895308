import { useEthers } from "@usedapp/core";
import { useEffect } from "react";
import { Outlet as RouterOutlet } from "react-router-dom";
import { useOrdersContext } from "../../hooks/useOrdersContext";
import { NetworkRequestState } from "../../types";

import { Layout } from "../Layout";

const Orders = () => {
  const { account } = useEthers();
  const {
    orders,
    ordersRetrievalStatus,
    getPrintOrders,
    clearOrdersRetrievalStatus,
  } = useOrdersContext();

  useEffect(() => {
    if (!account) {
      return;
    }

    const shouldRetrieveOrders =
      ordersRetrievalStatus === NetworkRequestState.IDLE;

    if (shouldRetrieveOrders) {
      getPrintOrders(account);
    }
  }, [
    getPrintOrders,
    clearOrdersRetrievalStatus,
    ordersRetrievalStatus,
    orders,
    account,
  ]);

  useEffect(() => {
    return () => {
      clearOrdersRetrievalStatus();
    };
  }, [clearOrdersRetrievalStatus]);

  return (
    <Layout.PageContent>
      <RouterOutlet />
    </Layout.PageContent>
  );
};

export default Orders;
