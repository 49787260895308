import { useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import { useNavigate } from "react-router-dom";
import { ReCaptchaInstance, load } from "recaptcha-v3";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { useAuthContext } from "../../hooks/useAuthContext";
import {
  CENTERED_FLEX_COLUMN_STYLES,
  PRINT_ORDER_ROUTES,
  RECAPTCHA_V3_SITE_KEY,
  ROUTES,
} from "../../constants";

function Landing() {
  const { token, getUserNonce, authUser } = useAuthContext();
  const etherWalletInterface = useEthers();
  const { account, activateBrowserWallet, deactivate, library } =
    etherWalletInterface;
  const [recaptcha, setRecaptcha] = useState<ReCaptchaInstance | null>(null);

  const navigate = useNavigate();

  const onSignInClick = () => {
    activateBrowserWallet();
  };

  useEffect(() => {
    if (recaptcha) {
      return;
    }

    load(RECAPTCHA_V3_SITE_KEY, {
      autoHideBadge: true,
    }).then((recaptcha) => setRecaptcha(recaptcha));
  }, [recaptcha]);

  useEffect(() => {
    if (account && token) {
      navigate(`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`);
    }
  }, [account, token, navigate]);

  useEffect(() => {
    const signer = library?.getSigner();

    if (account && !token && signer && recaptcha) {
      recaptcha.execute("submit").then((token) => {
        getUserNonce(account, token)
          .then((nonce) => signer.signMessage(`Nonce: ${nonce}`))
          .then((signature) => {
            authUser(account, signature);
          })
          .catch(() => deactivate());
      });
    }

    /**
     * "library" dependency seems to be remade following a "deactivate"
     * call from useAuthErrorHandling. Observed when routing user
     * back to this route when jwt goes stale & wallet is deactivated.
     * It's crucial not to prompt for more than a single signature
     * request at a time to provide smooth UX and including "library"
     * in dependency array causes two signature requests to be made
     * in the aforementioned scenario
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, token, recaptcha, getUserNonce, authUser]);

  const signedIn = account && token;

  return (
    <Container>
      <Box marginBottom={2} display="flex" justifyContent="center">
        <Box sx={CENTERED_FLEX_COLUMN_STYLES}>
          {!recaptcha ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h5" gutterBottom>
                Sign in to get started
              </Typography>
              <Button
                variant="contained"
                onClick={onSignInClick}
                disabled={!!account && !token}
              >
                {!signedIn ? "Connect Wallet" : "Disconnect Wallet"}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default Landing;
