import { EtherMarketPriceResponseData } from "../types";
import { authFetch } from "./authFetch";

const ETHER_PRICE_DATA_URL = "https://api.coinbase.com/v2/prices/ETH-USD/spot";

export const getEtherPriceData = () => {
  return authFetch(ETHER_PRICE_DATA_URL)
    .then((response) => response.json())
    .then((priceData: EtherMarketPriceResponseData) => priceData.data);
};
