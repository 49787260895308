import { useCallback, useContext, useEffect } from "react";
import {
  Outlet as RouterOutlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useEthers } from "@usedapp/core";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Error from "@mui/icons-material/Error";
import ReceiptLong from "@mui/icons-material/ReceiptLong";
import Print from "@mui/icons-material/PrintSharp";

import {
  Layout,
  NavRoutesList,
  NavRoutesItem,
  NavRoutesLink,
  DESKTOP_LEFT_GAP,
} from "./modules/Layout";

import { EtherPriceDataContext } from "./context";
import { useAuthContext } from "./hooks/useAuthContext";
import { usePrintingPricesContext } from "./hooks/usePrintingPricesContext";
import { useProductSelection } from "./hooks/useProductSelection";

import "./App.css";

import {
  CENTERED_FLEX_COLUMN_STYLES,
  PRINT_ORDER_ROUTES,
  ROUTES,
} from "./constants";
import { useAppDataStatus } from "./hooks/useAppDataStatus";

function App() {
  const { token, clearToken } = useAuthContext();
  const etherWalletInterface = useEthers();
  const { account } = etherWalletInterface;

  const { getEtherPriceData, clearEtherPriceRetrievalError } = useContext(
    EtherPriceDataContext
  );

  const { getPrintingPrices, clearPrintingPricesRequestStatus } =
    usePrintingPricesContext();

  const { appDataRetrievalFailed } = useAppDataStatus();

  const { clearPrintPreviewsRequestStatus } = useProductSelection();

  const theme = useTheme();
  const desktopLayout = useMediaQuery(theme.breakpoints.up("md"));

  const location = useLocation();
  const navigate = useNavigate();

  const orderRoute = location.pathname.startsWith(`/${ROUTES.PRINT}`);
  const homeRoute = location.pathname.startsWith(`/${ROUTES.ORDERS}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getNavLabel = useCallback(() => {
    if (orderRoute) {
      document.title = "Wallet Prints | Print";

      return "Print";
    }

    document.title = "Wallet Prints | Orders";
    return "Orders";
  }, [orderRoute]);

  useEffect(() => {
    if (!account || !token) {
      navigate(`/${ROUTES.LANDING}`);
      clearPrintingPricesRequestStatus();
      clearEtherPriceRetrievalError(false);

      return;
    }
  }, [
    account,
    token,
    clearEtherPriceRetrievalError,
    clearPrintingPricesRequestStatus,
    navigate,
  ]);

  useEffect(() => {
    // clear auth token if wallet account is changed.
    // this will trigger a redirect to landing page
    // to perform a reauthentication
    clearToken();
  }, [account, clearToken]);

  useEffect(() => {
    if (account && token) {
      getPrintingPrices();
      getEtherPriceData();
    }
  }, [account, token, getPrintingPrices, getEtherPriceData]);

  const navRoutesItemBaseProps = {
    mobile: !desktopLayout,
  };

  const retryAppDataRetrieval = useCallback(() => {
    getPrintingPrices();
    getEtherPriceData();
  }, [getPrintingPrices, getEtherPriceData]);

  useEffect(() => {
    return () => {
      clearPrintPreviewsRequestStatus();
      clearPrintingPricesRequestStatus();
      clearEtherPriceRetrievalError(false);
    };
  }, [
    clearPrintPreviewsRequestStatus,
    clearPrintingPricesRequestStatus,
    clearEtherPriceRetrievalError,
  ]);

  const routes = (
    <NavRoutesList mobile={!desktopLayout}>
      <NavRoutesItem {...navRoutesItemBaseProps} selected={orderRoute}>
        <NavRoutesLink to={`/${ROUTES.PRINT}/${PRINT_ORDER_ROUTES.NFT_SELECT}`}>
          <Print sx={{ marginBottom: 1 }} fontSize="large" />
          <Typography variant="h6">Print</Typography>
        </NavRoutesLink>
      </NavRoutesItem>
      <NavRoutesItem {...navRoutesItemBaseProps} selected={homeRoute}>
        <NavRoutesLink to={`/${ROUTES.ORDERS}`}>
          <ReceiptLong
            sx={{ marginBottom: 1, transform: "scale(-1, 1)" }}
            fontSize="large"
          />
          <Typography variant="h6">Orders</Typography>
        </NavRoutesLink>
      </NavRoutesItem>
    </NavRoutesList>
  );

  return (
    <Container
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Layout.Header
        label={token ? getNavLabel() : ""}
        mobile={!desktopLayout}
      />
      <Box display="flex" gap={desktopLayout ? DESKTOP_LEFT_GAP : 0} flex="1">
        {token && desktopLayout && (
          <Layout.DesktopNav>{routes}</Layout.DesktopNav>
        )}
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          sx={{ display: "flex", flex: "1 1 0%" }}
          marginBottom={desktopLayout ? 0 : 5}
        >
          <RouterOutlet />
        </Box>
      </Box>
      {token && !desktopLayout && <Layout.MobileNav>{routes}</Layout.MobileNav>}
      <Dialog open={appDataRetrievalFailed}>
        <DialogContent>
          <Box sx={CENTERED_FLEX_COLUMN_STYLES}>
            <Box sx={CENTERED_FLEX_COLUMN_STYLES} marginBottom={4}>
              <Error fontSize="large" color="error" />
            </Box>
            <Typography textAlign="center" variant="body1">
              Could not retrieve data required to run application.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={retryAppDataRetrieval}>Retry</Button>
          <Button onClick={clearToken}>Exit</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default App;
