export interface User {
  walletAddress: string;
  orderIds: string[];
  nonce: string;
}

export interface CreateUserResponse {
  data: User;
}

export interface GetUserNonceResponse {
  data: string;
}

export interface AuthUserResponse {
  data: string;
}

export enum NetworkRequestState {
  IDLE = "IDLE",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface NFTContractData {
  address: string;
}

interface OwnedNFTID {
  tokenId: string;
  tokenMetadata?: {
    tokenType: string;
  };
}

export interface OwnedNFT {
  contract: NFTContractData;
  id: OwnedNFTID;
  balance: string;
}

export interface OwnedNFTsResponse {
  ownedNfts: OwnedNFT[];
  totalCount: number;
  blockHash: string;
  pageKey: string;
}

export interface GetOwnedNFTsReturn {
  ownedNfts: OwnedNFT[];
  pageKey: string;
}

export interface NFTMedia {
  bytes: number;
  format: string;
  gateway: string;
  raw: string;
  thumbnail: string;
}

export interface NFTData {
  contract: NFTContractData;
  id: OwnedNFTID;
  title: string;
  description: string;
  media: NFTMedia[];
  metadata: {
    name?: string;
    description?: string;
    image?: string;
  };
}

export interface PrintPreview {
  image: string;
  intrinsicHeight: number;
  intrinsicWidth: number;
  pixelsPerInch: number;
  rotated: boolean;
  variant: PosterVariantKeys;
}

export interface PrintPreviewsResponseData {
  data: PrintPreview[];
}

/**
 * prints-api types
 */

export interface OrderFileOption {
  id: string;
  value: string;
}

export interface OrderFile {
  type?: string;
  url: string;
  options?: OrderFileOption[];
  filename?: string;
  placement: string;
}

export interface OrderItemProduct {
  variant_id: number;
  product_id: number;
  image: string;
  name: string;
}

export interface OrderItem {
  id?: number;
  external_id?: string;
  variant_id: number;
  sync_variant_id?: string;
  external_variant_id?: string;
  warehouse_product_variant_id?: string;
  quantity: number;
  price?: string;
  retail_price?: string;
  name?: string;
  product: OrderItemProduct;
  files: OrderFile[];
}

export interface OrderRecipient {
  name?: string;
  address1: string;
  address2?: string;
  city: string;
  state_code: string;
  state_name?: string;
  country_code: string;
  country_name?: string;
  zip: string;
  phone?: string;
  email?: string;
  tax_number?: string;
}

export interface ShippingFormValues {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  stateCode: string;
  countryCode: string;
  zip: string;
}

export interface State {
  code: string;
  name: string;
}

export interface Country {
  code: string;
  name: string;
  states: State[] | null;
}

export type OrderStatus =
  | "draft"
  | "pending"
  | "failed"
  | "canceled"
  | "inprocess"
  | "onhold"
  | "partial"
  | "fulfilled";

export interface OrderCosts {
  currency: string;
  subtotal: number;
  discount: number;
  shipping: number;
  digitization: number;
  tax: number;
  vat: number;
  total: number;
}

export interface OrderPricingBreakdown {
  customer_pays: string;
  printful_price: string;
  profit: string;
  currency_symbol: string;
}

export interface OrderShipment {
  id: number;
  carrier: string;
  service: string;
  tracking_number: number;
  tracking_url: string;
  created: number;
  ship_date: string;
  shipped_at: number;
  reshipment: boolean;
}

export interface SubmitOrderResponse {
  data: OrderDetails;
}

export interface PrintingPrices {
  FRAMED_POSTER_14_BY_14: number;
  FRAMED_POSTER_12_BY_16: number;
  FRAMED_POSTER_16_BY_20: number;
}

export interface GetPrintingPricesResponse {
  data: PrintingPrices;
}

export interface GetCountriesResponseData {
  data: {
    code: number;
    result: Country[];
  };
}

export enum PosterVariantKeys {
  FRAMED_POSTER_14_BY_14 = "FRAMED_POSTER_14_BY_14",
  FRAMED_POSTER_12_BY_16 = "FRAMED_POSTER_12_BY_16",
  FRAMED_POSTER_16_BY_20 = "FRAMED_POSTER_16_BY_20",
}

export interface EtherMarketPriceResponseData {
  data: {
    amount: string;
    base: string;
    currency: string;
  };
}

export interface EtherPriceData {
  price: number;
}

export interface OrderEstimateResponseData {
  data: {
    costs: OrderCosts;
    checkoutId: string;
  };
}

export enum PrintfulOrderStatus {
  DRAFT = "draft",
  PENDING = "pending",
  FAILED = "failed",
  CANCELED = "canceled",
  IN_PROCESS = "inprocess",
  ON_HOLD = "onhold",
  PARTIAL = "partial",
  FULFILLED = "fulfilled",
  ARCHIVED = "archived",
}

export interface NFTBaseData {
  name: string;
  image: string;
}

export interface OrderDetails {
  recipient: OrderRecipient;
  trackingUrl?: string;
  costs: OrderCosts;
  status: PrintfulOrderStatus;
  printPreviewUrl: string;
  id: string;
  nftData: NFTBaseData;
  variant: PosterVariantKeys;
  created: number;
  updated: number;
}

export interface OrderDetailsResponse {
  data: OrderDetails;
}

export interface OrdersListResponse {
  data: OrderDetails[];
}

export enum SupportedCurrencies {
  ETHER = "ETH",
}
