import { OwnedNFTsResponse, NFTData, GetOwnedNFTsReturn } from "../types";
import { authFetch } from "./authFetch";

const API_BASE_URL = "https://nft-api.proxy.walletprints.com";

function getNFTs(owner: string, pageKey?: string): Promise<GetOwnedNFTsReturn> {
  const url = `${API_BASE_URL}/getNFTs?owner=${owner}${
    pageKey ? `&pageKey=${pageKey}` : ""
  }`;

  return authFetch(url)
    .then((response) => response.json())
    .then((ownedNFTsResponse: OwnedNFTsResponse) => ({
      ownedNfts: ownedNFTsResponse.ownedNfts,
      pageKey: ownedNFTsResponse.pageKey || "",
    }));
}

function getNFTMetadata(
  contractAddress: string,
  tokenId: string
): Promise<NFTData> {
  const url = `${API_BASE_URL}/getNFTMetadata?contractAddress=${contractAddress}&tokenId=${tokenId}`;

  return authFetch(url)
    .then((response) => response.json())
    .then((responseData: NFTData) => responseData);
}

export { getNFTs, getNFTMetadata };
