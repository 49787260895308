import Typography from "@mui/material/Typography";

const PrintQualityInfoText = () => {
  return (
    <>
      <Typography variant="body2" gutterBottom>
        DPI (dots per inch) is a printing term. Digital devices display images
        in pixels, and printers print images in dots. DPI is calculated using
        the digital image's pixel dimensions.
      </Typography>
      <Typography variant="body2">
        Higher DPI = more dots = a sharper print.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Lower DPI = fewer dots = a blurrier print.
      </Typography>
      <Typography variant="body2" fontWeight="bold">
        DPI should be at least 150.
      </Typography>
      <Typography variant="body2" fontWeight="bold">
        Going beyond 300 DPI won't improve the print quality.
      </Typography>
    </>
  );
};

export default PrintQualityInfoText;
