import React, { ReactElement } from "react";
import styled from "styled-components";

const SelectableList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const SelectableListOption = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export interface ImageSelectOptionProps {
  value: string;
  children: React.ReactNode;
  "data-contract-address"?: string;
  "data-id"?: string;
  "data-title"?: string;
}

interface EnhancedImageSelectOptionProps extends ImageSelectOptionProps {
  selected: boolean;
  disabled?: boolean;
  onClick: (value: string) => void;
  children: React.ReactNode;
}

const ImageSelectOption: React.FC<ImageSelectOptionProps> = (props) => {
  const { children, value, ...rest } = props;

  return <div {...rest}>{children}</div>;
};

const EnhancedImageSelectOption: React.FC<EnhancedImageSelectOptionProps> = (
  props
) => {
  const { children, onClick, selected, value, disabled, ...rest } = props;

  const onOptionClick: React.MouseEventHandler<HTMLLIElement> = (event) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    onClick(value);
  };

  return (
    <SelectableListOption
      onClick={onOptionClick}
      style={{
        border: selected ? "2px solid white" : "2px solid #656567",
        opacity: disabled ? 0.3 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      {...rest}
    >
      {children}
    </SelectableListOption>
  );
};

export interface ImageSelectProps {
  value: string;
  disabled?: boolean;
  onChange: (selectedValue: string) => void;
  children:
    | ReactElement<ImageSelectOptionProps>
    | ReactElement<ImageSelectOptionProps>[];
}

const ImageSelect: React.FC<ImageSelectProps> = (props) => {
  const { children, disabled, value, onChange } = props;

  return (
    <SelectableList>
      {React.Children.map(children, (child) => {
        const childProps = (child as ReactElement<ImageSelectOptionProps>)
          .props;

        return (
          <EnhancedImageSelectOption
            {...childProps}
            onClick={() => onChange(childProps.value)}
            selected={childProps.value === value}
            disabled={disabled}
          />
        );
      })}
    </SelectableList>
  );
};

export default ImageSelect;
export { ImageSelectOption };
