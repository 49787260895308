import React, { createContext, useCallback, useState } from "react";

import {
  NetworkRequestState,
  OrderRecipient,
  PosterVariantKeys,
  OrderDetails,
  NFTBaseData,
} from "../../types";
import {
  submitPrintOrder as submitPrintOrderRequest,
  getOrders as getOrdersRequest,
} from "../../network/prints";
import { ORDER_REQUEST_ERRORS } from "../../constants";
import { useAuthErrorHandling } from "../../hooks/useAuthErrorHandling";
import { isInvalidTokenError } from "../../utils";

interface ContextValue {
  orderSubmitStatus: NetworkRequestState;
  orderSubmitError: string;
  orders: OrderDetails[];
  ordersRetrievalStatus: NetworkRequestState;
  ordersRetrievalError: string;
  submittedPrintOrder: OrderDetails | null;
  getPrintOrders: (walletAddress: string) => void;
  submitPrintOrder: (
    recipient: OrderRecipient,
    variant: PosterVariantKeys,
    imageUrl: string,
    transactionHash: string,
    checkoutId: string,
    nftData: NFTBaseData
  ) => void;
  clearOrderSubmitData: () => void;
  clearOrdersRetrievalStatus: () => void;
}

const OrdersContext = createContext<ContextValue | undefined>(undefined);

const OrdersContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [orders, setOrders] = useState<OrderDetails[]>([]);
  const [submittedPrintOrder, setSubmittedPrintOrder] =
    useState<OrderDetails | null>(null);
  const [ordersRetrievalStatus, setOrdersRetrievalStatus] =
    useState<NetworkRequestState>(NetworkRequestState.IDLE);
  const [ordersRetrievalError, setOrdersRetrievalError] = useState("");
  const [orderSubmitStatus, setOrderSubmitStatus] = useState(
    NetworkRequestState.IDLE
  );
  const [orderSubmitError, setOrderSubmitError] = useState("");

  const { onInvalidTokenError } = useAuthErrorHandling();

  const clearOrderSubmitData = useCallback(() => {
    setOrderSubmitStatus(NetworkRequestState.IDLE);
    setOrderSubmitError("");
    setSubmittedPrintOrder(null);
  }, []);

  const clearOrdersRetrievalStatus = useCallback(() => {
    setOrdersRetrievalStatus(NetworkRequestState.IDLE);
    setOrdersRetrievalError("");
  }, []);

  const getPrintOrders = useCallback(
    (walletAddress: string) => {
      const sortMostRecentlyUpdatedFirst = (
        orderA: OrderDetails,
        orderB: OrderDetails
      ) => orderB.updated - orderA.updated;

      setOrdersRetrievalStatus(NetworkRequestState.LOADING);
      getOrdersRequest(walletAddress)
        .then((orders) => {
          setOrders(orders.sort(sortMostRecentlyUpdatedFirst));
          setOrdersRetrievalStatus(NetworkRequestState.SUCCESS);
        })
        .catch((error) => {
          setOrdersRetrievalStatus(NetworkRequestState.ERROR);
          setOrdersRetrievalError(ORDER_REQUEST_ERRORS.GET_ORDERS_LIST_ERROR);

          if (isInvalidTokenError(error)) {
            onInvalidTokenError();
          }
        });
    },
    [onInvalidTokenError]
  );

  const submitPrintOrder = (
    recipient: OrderRecipient,
    variant: PosterVariantKeys,
    imageUrl: string,
    transactionHash: string,
    checkoutId: string,
    nftData: NFTBaseData
  ) => {
    setOrderSubmitStatus(NetworkRequestState.LOADING);
    submitPrintOrderRequest(
      recipient,
      variant,
      imageUrl,
      transactionHash,
      checkoutId,
      nftData
    )
      .then((printOrder) => {
        setSubmittedPrintOrder(printOrder);
        setOrderSubmitStatus(NetworkRequestState.SUCCESS);
      })
      .catch((error: Error) => {
        setOrderSubmitStatus(NetworkRequestState.ERROR);
        setOrderSubmitError(error.message);
      });
  };

  const contextValue: ContextValue = {
    orders,
    getPrintOrders,
    submittedPrintOrder,
    ordersRetrievalStatus,
    ordersRetrievalError,
    clearOrdersRetrievalStatus,
    submitPrintOrder,
    orderSubmitStatus,
    clearOrderSubmitData,
    orderSubmitError,
  };

  return (
    <OrdersContext.Provider value={contextValue}>
      {children}
    </OrdersContext.Provider>
  );
};

export { OrdersContextProvider };
export default OrdersContext;
